import { SvgIcon } from "@material-ui/core";

export const HeartIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M480.25 156.355c0 161.24-224.25 324.43-224.25 324.43S31.75 317.595 31.75 156.355c0-91.41 70.63-125.13 107.77-125.13 77.65 0 116.48 65.72 116.48 65.72s38.83-65.73 116.48-65.73c37.14.01 107.77 33.72 107.77 125.14z" />
    </SvgIcon>
  );
};

export default HeartIcon;
