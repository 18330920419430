import { SvgIcon } from "@material-ui/core";

export const PotionsAndItemsIcon = (props) => {
  return (
    <>
      <SvgIcon viewBox="0 0 512 512" {...props}>
        <path d="m381.7 27-14.5 20.4 49.5 35.1 14.5-20.3L381.7 27zm-57.4 12.3L310.2 60l111.3 76.2 14.1-20.7-111.3-76.2zM317 92.7l-69.9 82.8c-10.3-2.1-20.9-3.2-31.8-3.2-86.8 0-157.25 70.5-157.25 157.3C58.05 416 128.5 487 215.3 487s157.3-71 157.3-157.4c0-37.8-13.4-72.5-35.6-99.6l51.4-86.9L317 92.7zm7.4 39 29.5 19.5-46.5 78c26.1 23.9 42.5 58.2 42.5 96.4 0 72-58.5 130.4-130.6 130.4-72 0-130.55-58.4-130.55-130.4 0-72.1 58.55-130.6 130.55-130.6 22.6 0 43.9 5.8 62.5 15.9l42.6-79.2zm-31.5 180.5c-47.3-.7-126.2 58.3-178.3 13.5.1 57.7 47 104.3 104.7 104.3 56.1 0 102-44 104.6-99.5-7-13.2-17.8-18.1-31-18.3zm-11.5 23.3c5.1-.1 9.7 1.5 12.7 5 6.9 7.9 3.2 22.4-8.3 32.4-11.4 10-26.3 11.7-33.2 3.8-6.9-7.9-3.2-22.4 8.2-32.4 6.5-5.6 14-8.6 20.6-8.8zm-56.5 49.1c1.2 0 2.4.1 3.7.3 9.9 1.5 17.2 8.2 16.2 15.1-1 7-9.8 11-19.8 10-9.9-2-17.2-8-16.2-15.3.9-6 7.7-10.1 16.1-10.1z" />
      </SvgIcon>
      <SvgIcon viewBox="0 0 512 512" {...props}>
        <path d="M352.75 39.47l-33.094 83.374 111.156 111.28 58.22-58.28L352.75 39.47zm-160.344 5.218c-98.25 66.974-166.87 161.246-169.47 288.906 62.25-119.817 162.457-225.092 255.22-269.75l-85.75-19.157zM97.75 60.156L68.187 89.72c7.276 6.96 14.53 13.893 21.813 20.843 8.887-10.39 18.37-20.35 28.313-29.907l-20.563-20.5zm353.813 0L425.688 86l28.718 28.75c8.91-8.36 17.822-16.728 26.72-25.094l-29.563-29.5zm-263.938 89.75c-9.44 8.552-18.824 17.596-28.063 27.063 25.263 24.13 50.526 48.323 75.688 72.75l26.188-26.126-73.813-73.688zm153.313 20.72L68 443.155l29.313 29.22c83.48-96.13 175.757-186.498 269.812-275.532l-26.188-26.22zm-28.25 104.155c-8.692 8.42-17.35 16.853-25.97 25.314 55.896 55.38 111.166 112.353 165.157 172.437l29.438-29.374L312.688 274.78z" />
      </SvgIcon>
    </>
  );
};

export default PotionsAndItemsIcon;
