import { Button } from "@mui/material";

export const SecondaryButton = ({ disabled, label, onClick }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default SecondaryButton;
