import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Paper } from "@mui/material/";
import { Route, Routes } from "react-router-dom";

import useHero from "@entities/heroes/hooks/useHero";
import Header from "@entities/heroes/ui/HeroCard/Header/Header";
import Hero from "@widgets/hero/Hero";
import { Campaign } from "@widgets/campaign";
import { Store } from "@widgets/store";
import AcquireEquipmentModal from "@widgets/hero-equipment/AcquireEquipmentModal";
import UseItemModal from "@widgets/hero-equipment/UseItemModal";
import UpdateGoldModal from "@widgets/hero/UpdateGoldModal";
import UpdateAttributeModal from "@widgets/hero/UpdateAttributeModal";
import EquipmentModal from "@widgets/hero-equipment/EquipmentModal";
import ConfigureSpellsModal from "@widgets/hero/ConfigureSpellsModal";
import ViewMonsterModal from "@widgets/campaign/ViewMonsterModal";
import { ErrorPage } from "@pages/error/index";

const HeroPaper = styled(Paper)`
  height: 100%;
  width: 100%;
  max-width: 700px;
  min-height: 100vh;
`;

export const HeroCard = () => {
  const { id } = useParams();
  const { hero } = useHero(id);

  if (!hero || Object.keys(hero).length === 0) return null;
  return (
    <HeroPaper elevation={0}>
      <Header />
      <AcquireEquipmentModal />
      <ConfigureSpellsModal />
      <EquipmentModal />
      <UseItemModal />
      <UpdateGoldModal />
      <UpdateAttributeModal />
      <ViewMonsterModal />
      <Routes>
        <Route
          path={`card`}
          element={<Hero hero={hero} />}
          errorElement={<ErrorPage />}
        />
        <Route
          path={`store`}
          element={<Store />}
          errorElement={<ErrorPage />}
        />
        <Route
          path={`campaign`}
          element={<Campaign />}
          errorElement={<ErrorPage />}
        />
      </Routes>
    </HeroPaper>
  );
};

export default HeroCard;
