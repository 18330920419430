import filter from "lodash/filter";
import { concat } from "lodash";
import { campaignIds } from "@entities/campaign/model/constants/campaigns";

export const getCampaignItems = (items, campaign) => {
  const baseGameItems = filter(
    items,
    (item) => item?.campaign === campaignIds.baseGame
  );

  const campaignItems =
    campaign !== campaignIds.baseGame
      ? filter(items, (item) => item?.campaign === campaign)
      : [];
  return concat(baseGameItems, campaignItems);
};
