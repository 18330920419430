import { useRouteError } from "react-router-dom";
import { Paper } from "@mui/material/";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Paper elevation={0}>
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </Paper>
  );
};

export default ErrorPage;
