import React from "react";
import styled from "styled-components";

import HeroAttributes from "@entities/heroes/ui/HeroCard/HeroAttributes/HeroAttributes";
import HeroProfile from "@entities/heroes/ui/hero-profile/HeroProfile";
import HeroEquipment from "../hero-equipment/HeroEquipment";

import UpdateGoldModal from "./UpdateGoldModal";

const HeroCardContainer = styled.div`
  justify-content: center;
  width: 100%;
`;

export const Hero = () => {
  return (
    <>
      <UpdateGoldModal />
      <HeroCardContainer container spacing={0.5} direction="column">
        <HeroProfile />
        <HeroAttributes />
        <HeroEquipment />
      </HeroCardContainer>
    </>
  );
};

export default Hero;
