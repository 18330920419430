import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";

import CardButton from "@shared/ui/Cards/CardButton";
import { CardsContainer } from "@shared/ui/Cards/Cards.styled";
import { selectedHeroPotionsAndTools } from "@entities/heroes/model/heroesSelectors";
import { updateModal } from "@entities/modal/model/modalSlice";

export const HeroInventorySection = () => {
  const dispatch = useDispatch();
  const potionsAndItems = useSelector(selectedHeroPotionsAndTools);

  const inventoryItems = potionsAndItems.map((item) => {
    const { id, type, name, image, backImage, isExhausted } = item;
    const imageUrl = `/${type.toLowerCase()}/${image}.jpeg`;
    const backImageUrl = `/common/${backImage}.jpeg`;
    const imageToDisplay = isExhausted === true ? backImageUrl : imageUrl;
    const onItemClick = async (item) => {
      dispatch(
        updateModal({
          name: "useItemModal",
          itemToUse: item,
        })
      );
    };
    return (
      <CardButton
        key={id}
        type={type}
        imageURL={imageToDisplay}
        name={name}
        showCard={true}
        onClick={() => {
          onItemClick(item);
        }}
      />
    );
  });
  return potionsAndItems.length === 0 ? (
    <Typography gutterBottom variant="body" component="div" align="center">
      No potions or items available.
    </Typography>
  ) : (
    <CardsContainer>{inventoryItems}</CardsContainer>
  );
};

export default HeroInventorySection;
