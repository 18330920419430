/* src/App.js */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Modal, Typography } from "@mui/material/";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import CardButton from "@shared/ui/Cards/CardButton";
import useEquipmentAPI from "@entities/equipment/hooks/useEquipmentAPI";
import { clearModal, updateModal } from "@entities/modal/model/modalSlice";
import { useItemModal } from "@entities/modal/model/modalSelectors";

export const UseItemModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector(useItemModal);
  const { updateHeroEquipment, deleteHeroEquipment } = useEquipmentAPI();
  const { isLoading, name: modalName, itemToUse } = modalState;
  const isOpen = modalName === "useItemModal";
  if (!itemToUse) return null;
  const { id, type, image, name, isExhausted, isExhaustable, isConsumable } =
    itemToUse;
  const imageUrl = `/${type.toLowerCase()}/${image}.jpeg`;

  const handleClose = () => dispatch(clearModal());

  const handleUseRefreshClick = async () => {
    if (isExhaustable) {
      dispatch(updateModal({ isLoading: true }));
      await updateHeroEquipment({
        input: { id, isExhausted: !isExhausted },
      });
    } else if (isConsumable) {
      dispatch(updateModal({ isLoading: true }));
      await deleteHeroEquipment(id);
    }
    dispatch(clearModal());
  };
  const exhaustableButtonLabel = isExhausted ? "Refresh" : "Exhaust";
  const consumableButtonLabel = isConsumable ? "Use" : "";
  const buttonLabel = isConsumable
    ? consumableButtonLabel
    : isExhaustable
    ? exhaustableButtonLabel
    : "";
  const closeButtonLabel = isExhaustable || isConsumable ? "Cancel" : "Close";
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <CardButton
            key={id}
            type={type}
            imageURL={imageUrl}
            name={name}
            showCard={true}
            zoom={true}
          />
          {isConsumable ? (
            <Typography>
              This item is consumable and will be removed when used.
            </Typography>
          ) : null}
          <ButtonsContainer>
            {isExhaustable || isConsumable ? (
              <ModalButton
                variant="contained"
                color="secondary"
                onClick={handleUseRefreshClick}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  buttonLabel
                )}
              </ModalButton>
            ) : null}
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {closeButtonLabel}
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default UseItemModal;
