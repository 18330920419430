import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";

import {
  TabsContainer,
  TabPanelContainer,
  a11yProps,
  TabPanel,
} from "@shared/ui/Tabs";
import CardsSection from "@shared/ui/Cards/CardsSection";
import { WeaponIcon } from "@entities/equipment/ui/WeaponIcon";
import { ShieldIcon } from "@entities/equipment/ui/ShieldIcon";
import { PotionsAndItemsIcon } from "@entities/equipment/ui/PotionsAndItemsIcon";
import {
  selectedHero,
  selectedHeroEquipmentNames,
} from "@entities/heroes/model/heroesSelectors";
import HeroProfile from "@entities/heroes/ui/hero-profile/HeroProfile";
import { updateModal } from "@entities/modal/model/modalSlice";
import {
  weaponStoreListForHeroCampaign,
  armorStoreListForHeroCampaign,
  itemsAndPotionsStoreListForHeroCampaign,
} from "@features/campaign/view-store-items/storeSelectors";

const InventoryContainer = styled.div``;

export const Store = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hero = useSelector(selectedHero);
  const weaponStoreList = useSelector(weaponStoreListForHeroCampaign);
  const armorStoreList = useSelector(armorStoreListForHeroCampaign);
  const itemsAndPotionsStoreList = useSelector(
    itemsAndPotionsStoreListForHeroCampaign
  );
  const heroEquipmentNames = useSelector(selectedHeroEquipmentNames);
  const [value, setValue] = useState(0);

  if (!hero) return null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleItemClick = (item) => {
    dispatch(
      updateModal({
        name: "acquireEquipmentModal",
        itemToAcquire: item,
      })
    );
  };

  return (
    <>
      <HeroProfile />
      <InventoryContainer
        container
        item
        spacing={0.5}
        alignItems="space-between"
        justifyContent="center"
        direction="column"
        // wrap="wrap"
      >
        <TabsContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab
              {...a11yProps(0)}
              icon={
                <>
                  <WeaponIcon fontSize="large" />
                </>
              }
              aria-label="weapons"
            />
            <Tab
              {...a11yProps(1)}
              icon={
                <>
                  <ShieldIcon fontSize="large" />
                </>
              }
              aria-label="armor"
            />
            <Tab
              {...a11yProps(2)}
              icon={<PotionsAndItemsIcon fontSize="large" />}
              aria-label="potions"
            />
          </Tabs>
        </TabsContainer>
        <TabPanelContainer heightAdjustment="130px">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CardsSection
              equippedItemNames={heroEquipmentNames}
              items={weaponStoreList}
              onItemClick={handleItemClick}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <CardsSection
              equippedItemNames={heroEquipmentNames}
              items={armorStoreList}
              onItemClick={handleItemClick}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <CardsSection
              equippedItemNames={heroEquipmentNames}
              items={itemsAndPotionsStoreList}
              onItemClick={handleItemClick}
            />
          </TabPanel>
        </TabPanelContainer>
      </InventoryContainer>
    </>
  );
};

export default Store;
