/* src/App.js */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Modal } from "@mui/material/";
import CircularProgress from "@mui/material/CircularProgress";
import indexOf from "lodash/indexOf";
import { Typography } from "@material-ui/core";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import CardButton from "@shared/ui/Cards/CardButton";
import {
  selectedHero,
  selectedHeroEquipmentNames,
} from "@entities/heroes/model/heroesSelectors";
import useEquipmentAPI from "@entities/equipment/hooks/useEquipmentAPI";
import { clearModal, updateModal } from "@entities/modal/model/modalSlice";
import { acquireEquipmentModal } from "@entities/modal/model/modalSelectors";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";

export const AcquireEquipmentModal = () => {
  const dispatch = useDispatch();
  const { id: heroId, gold } = useSelector(selectedHero);
  const modalState = useSelector(acquireEquipmentModal);
  const { updateHero } = useHeroAPI();

  const { createHeroEquipment } = useEquipmentAPI();
  const heroEquipmentNames = useSelector(selectedHeroEquipmentNames);
  const { isLoading, name: modalName, itemToAcquire } = modalState;

  const isOpen = modalName === "acquireEquipmentModal";
  if (!itemToAcquire) return null;

  const handleClose = () => dispatch(clearModal());
  const handleBuyClick = async () => {
    dispatch(updateModal({ isLoading: true }));
    const updatedHeroGold = Number(gold) - Number(itemToAcquire?.cost);
    await createHeroEquipment({ heroId, ...itemToAcquire });
    await updateHero({ id: heroId, gold: updatedHeroGold });
    dispatch(clearModal());
  };

  const { id, type, image, name, cost, isArtifact } = itemToAcquire;
  const imageUrl = `/${type.toLowerCase()}/${image}.jpeg`;
  const isAlreadyOwned = Boolean(indexOf(heroEquipmentNames, name) >= 0);
  const isTreasureItem = cost === null && isArtifact === false;
  const notEnoughGoldToPurchase =
    isArtifact !== true && Number(cost) > Number(gold) ? true : false;

  const canNotAcquire =
    (isArtifact && isAlreadyOwned) || notEnoughGoldToPurchase;
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <CardButton
            key={id}
            type={type}
            imageURL={imageUrl}
            name={name}
            showCard={true}
            zoom={true}
          />
          {notEnoughGoldToPurchase ? (
            <Typography>You do not have enough money</Typography>
          ) : null}
          {isAlreadyOwned ? (
            <Typography>You already own at least one of this item</Typography>
          ) : null}
          <ButtonsContainer>
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleBuyClick}
              disabled={isLoading || canNotAcquire}
            >
              {isLoading ? (
                <CircularProgress color="secondary" />
              ) : isArtifact || isTreasureItem ? (
                "Found"
              ) : (
                "Buy"
              )}
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default AcquireEquipmentModal;
