import styled from "styled-components";

export const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

export const TabsContainer = styled.div`
  margin-bottom: 0.5rem;
`;

// calculation reduces size of container so that the tabs does not scroll once the 100vh is reached
// adjust the px if the tab menu scrolls in the future
export const TabPanelContainer = styled.div`
  height: 100%;
`;
