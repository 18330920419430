import * as React from "react";
import { Button } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Paper } from "@mui/material/";
import { routes } from "@shared/lib/routes";

const MainContainer = styled(Paper)``;

const LogoImg = styled.img`
  width: calc(100vw);
  max-width: 600px;
  padding: 2rem;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/common/brick.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color;
  height: 100%;
`;

export const Splash = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`${routes.heroes}`);
  };
  return (
    <AppContainer>
      <LogoImg src="/common/heroquest-logo.png" alt="heroquest logo" />
      <MainContainer elevation={0}>
        <Button variant="contained" color="secondary" onClick={handleOnClick}>
          Get Started
        </Button>
      </MainContainer>
    </AppContainer>
  );
};

export default Splash;
