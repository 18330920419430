import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, CardContent, CardActions, Typography } from "@mui/material/";

import { CharacterIcon } from "@entities/characters/ui/CharacterIcon";
import { characterTypes } from "@entities/characters/model/characterTypes";
import ViewHeroButton from "@features/heroes/view-hero/ViewHeroButton";
import DeleteHeroButton from "@features/heroes/delete-hero/DeleteHeroButton";

const HeroCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CharacterText = styled(Typography)`
  &&& {
    margin-left: 0.5rem;
    line-height: 1;
  }
`;
export const HeroListItem = ({ id, name, character, gold = 0 }) => {
  return (
    <HeroCardContainer>
      <CardContentStyled>
        <CharacterIcon character={character} fontSize="large" />
        <Avatar>
          <CharacterText variant="subtitle1">{name}</CharacterText>
          <CharacterText variant="subtitle1">${gold}</CharacterText>
        </Avatar>
      </CardContentStyled>
      <CardActions>
        <ViewHeroButton id={id} />
        <DeleteHeroButton id={id} />
      </CardActions>
    </HeroCardContainer>
  );
};

HeroListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  character: PropTypes.oneOf(Object.values(characterTypes)),
  gold: PropTypes.number,
};

export default HeroListItem;
