import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  editedHero,
  selectedHeroWeapons,
  isLoading,
} from "@entities/heroes/model/heroesSelectors";
import useHeroAPI from "./useHeroAPI";

function useHero(id) {
  const { fetchHero } = useHeroAPI();
  const hero = useSelector(editedHero);
  const weapons = useSelector(selectedHeroWeapons);
  const isHeroLoading = useSelector(isLoading);
  useEffect(() => {
    fetchHero(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hero,
    isLoading: isHeroLoading,
    weapons,
  };
}

export default useHero;
