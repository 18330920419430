import { characterTypes } from "./characterTypes";

export const BARBARIAN = {
  character: characterTypes.BARBARIAN,
  displayName: "Barbarian",
  body: 8,
  attack: 3,
  defense: 2,
  mind: 2,
  gold: 0,
};

export const DWARF = {
  character: characterTypes.DWARF,
  displayName: "Dwarf",
  body: 7,
  attack: 2,
  defense: 2,
  mind: 3,
  gold: 0,
};

export const ELF = {
  character: characterTypes.ELF,
  displayName: "Elf",
  body: 6,
  attack: 2,
  defense: 2,
  mind: 4,
  gold: 0,
};

export const WIZARD = {
  character: characterTypes.WIZARD,
  displayName: "Wizard",
  body: 4,
  attack: 1,
  defense: 2,
  mind: 6,
  gold: 0,
};

export const characterClasses = {
  BARBARIAN,
  DWARF,
  WIZARD,
  ELF,
};

export const weaponSlots = 3;
export const armorSlots = 6;
export const inventorySlots = 9;
export const spellSlots = {
  WIZARD: 12,
  ELF: 3,
};

export default characterClasses;
