import React from "react";
import styled from "styled-components";
import {
  Typography,
  Paper,
  Modal,
  Stack,
  CircularProgress,
} from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import { selectedHeroId } from "@entities/heroes/model/heroesSelectors";
import { modal } from "@entities/modal/model/modalSelectors";
import {
  clearModal,
  updateModal,
  updateAttribute,
} from "@entities/modal/model/modalSlice";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";

const CalculationContainer = styled.div`
  display: flex;
  flex: row;
  justify-content: space-around;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`;

const UpdateAttributeModal = () => {
  const dispatch = useDispatch();
  const heroId = useSelector(selectedHeroId);
  const { updateHero } = useHeroAPI();
  const modalState = useSelector(modal);
  const { attribute, currentValue, modifier, isLoading } = modalState;
  const isOpen = modalState?.name === "updateAttribute";
  const newValue = Number(currentValue) + Number(modifier);
  const modifierColor =
    modifier > 0 ? "#388e3c" : modifier < 0 ? "#A30000" : undefined;
  const displayModifier = Math.abs(modifier);
  const isAttributeChanged = currentValue !== newValue;

  const handleCloseClick = () => {
    dispatch(clearModal());
  };
  const handleAddClick = () => {
    dispatch(updateAttribute(Number(modifier) + 1));
  };
  const handleMinusClick = () => {
    dispatch(updateAttribute(Number(modifier) - 1));
  };
  const handleSaveClick = async () => {
    dispatch(updateModal({ isLoading: true }));
    await updateHero({ id: heroId, [attribute]: newValue });
    dispatch(clearModal());
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={handleAddClick}
            >
              <AddIcon />
            </IconButton>
          </Stack>
          <CalculationContainer>
            <ValueContainer>
              <Typography variant="subtitle1">Current Value</Typography>
              <Typography variant="h4">{currentValue}</Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="subtitle1">Modifier</Typography>
              <Typography variant="h4" color={modifierColor}>
                {modifier < 0 ? " - " : " + "}
                {displayModifier}
              </Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="subtitle1">New Value</Typography>
              <Typography variant="h4" color={modifierColor}>
                {newValue}
              </Typography>
            </ValueContainer>
          </CalculationContainer>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <IconButton
              color="secondary"
              aria-label="minus"
              onClick={handleMinusClick}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
          <ButtonsContainer>
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleSaveClick}
              disabled={isLoading || !isAttributeChanged}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "Save"}
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleCloseClick}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default UpdateAttributeModal;
