import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Modal } from "@mui/material/";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import CardButton from "@shared/ui/Cards/CardButton";
import { spellTypes } from "@entities/equipment/model/equipmentTypes";
import { spellSlots } from "@entities/characters/model/characterClasses";
import { spellTypeBackImages } from "@entities/equipment/model/spells";
import useEquipmentAPI from "@entities/equipment/hooks/useEquipmentAPI";
import {
  selectedHeroSpells,
  selectedHero,
} from "@entities/heroes/model/heroesSelectors";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";
import { clearModal, updateModal } from "@entities/modal/model/modalSlice";
import { configureSpellsModal } from "@entities/modal/model/modalSelectors";

const SpellCardTypes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
`;

export const ConfigureSpellsModal = () => {
  const dispatch = useDispatch();
  const { fetchHero } = useHeroAPI();
  const { id, character } = useSelector(selectedHero);
  const modalState = useSelector(configureSpellsModal);
  const { selectedSpellTypes, isLoading } = modalState;
  const equippedSpells = useSelector(selectedHeroSpells);

  const { equipSpells } = useEquipmentAPI();
  const handleClose = () => dispatch(clearModal());

  const checkboxesAllowed = spellSlots[character] / 3;
  const checkboxesChecked = Object.values(selectedSpellTypes).filter(
    (typeOfSpell) => typeOfSpell === true
  ).length;

  const disableCheckboxes = checkboxesAllowed <= checkboxesChecked;

  const handleSpellCheckClick = (toggledType) => {
    if (selectedSpellTypes?.[toggledType] !== true && disableCheckboxes)
      return null;
    dispatch(
      updateModal({
        selectedSpellTypes: {
          ...selectedSpellTypes,
          [toggledType]: !selectedSpellTypes[toggledType],
        },
      })
    );
  };

  const { name } = modalState;
  const isOpen = name === "configureSpellType";

  const handleSave = async () => {
    const selectedSpellList = Object.keys(selectedSpellTypes).reduce(
      (list, spellType) => {
        if (selectedSpellTypes[spellType] === true) list.push(spellType);
        return list;
      },
      []
    );
    dispatch(updateModal({ isLoading: true }));
    await equipSpells({
      selectedSpellTypes: selectedSpellList,
      equippedSpells,
      heroId: id,
    });
    await fetchHero(id);
    await dispatch(clearModal());
  };

  const spellCheckBoxes = Object.keys(spellTypes).map((type, index) => {
    const backImageUrl = `/common/${spellTypeBackImages[type]}.jpeg`;
    return (
      <CardButton
        key={type}
        type={type}
        imageURL={backImageUrl}
        name={name}
        showCard={true}
        onClick={() => handleSpellCheckClick(type)}
        selected={selectedSpellTypes?.[type] === true}
        isSelectable={true}
      />
    );
  });

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <SpellCardTypes>{spellCheckBoxes}</SpellCardTypes>
          <ButtonsContainer>
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "Save"}
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default ConfigureSpellsModal;
