import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  grid: {
    margin: "0px",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#696969",
    },
    secondary: {
      main: "#F2D321",
      contrastText: "#424242",
    },
    error: {
      main: "#5d4037",
    },
    background: {
      // default: "#310000",
      // paper: "#731010",
      default: "#303030",
      paper: "#424242",
    },
    defaultText: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Do Hyeon",
  },
  shape: {},
});
