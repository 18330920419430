const campaignID = "mageOfTheMirror";

const intro = {
  number: 0,
  campaign: campaignID,
  title: "Intro",
  description:
    "At last you have returned from the Nothlands. I bear ill tidings, Our time together must be brief for your skills are sorely needed. The Elven kingdom of Athelorn is in turmoil. Queen Terrellia's young daughter, Millandriell, has been kidnapped. The Queen has in secret dispatched a courier to me. It seems the kidnapper is none other than her sister, the Archmage Sinestra. Sinestra has always been vain, paying more attention to her mirrors than to sage council of the wise. It would seem that this vanity has allowed her to become corrupted by Morcar and his allies. She now demands the Elven throne for herself, else the child's life is forfeit. As you know, none other the Elves may freely enter the Elven kingdom. The Elf will be sent ahead to begin preparations for the assault on Sinestra's Fortress. Meanwile, the rest of you will be lead by Elven guides through the kingdom blindfolded. You must rescue Millandriell, or else the Elven race will be doomed to conflict and civil war, or else become the pawns of Morcar. The turning of such a powerful force of good to the cause of Chaos would undoubtedly end our cause with alarming swiftness. You must not fail in this.",
};

const avengerReturns = {
  number: 1,
  campaign: campaignID,
  title: "Solo Quest I - The Avenger Returns",
  description:
    "As your first task, Queen Terrellia needs you to recover a family heirloom stolen by agents of Sinestra. A symbol of royal power, her possessing this item would be of detriment to our cause. No ordinary heirloom, this is the legendary sword of the royal house, known as the Avenger. The sword, for the time being, rests in a small fortress on the southern boarder of the Empire. You must secure the sword and return it to Queen Terrellia.",
};

const onSacredGround = {
  number: 2,
  campaign: campaignID,
  title: "Solo Quest II - On Sacred Ground",
  description:
    "Two of Princess Millandriell's handmaids survived the kidnapping, and are being held prisoner in a remote shrine housed in a cavern to the south. They may also have valuable information on where Sinestra has absconded the princess to. You must rescue the servants and return them to the Elven court",
};

const terrelliasMaze = {
  number: 3,
  campaign: campaignID,
  title: "Solo Quest III - Terrellia's Maze",
  description:
    "The Queen's guard train in a maze beneath her palace. Here the elite are separated from the merely exceptional. Sinestra has corrupted this place with her powers. To restore it, the Queen requires you to recover the Golden Book that lies within the Maze. Beware, for Sinestra has seeded the maze with her minions.",
};

const tormuksGuests = {
  number: 4,
  campaign: campaignID,
  title: "Tormuk's Guests",
  description:
    "“The servants the Elf has rescued have revealed the location in which Millandriell has been imprisoned: The Realm of Reflection. This mystical plane may be only entered via enchanted mirrors unlocked with rare Moonsilver. Only Tyrillon, the royal prospector can identify true Moonsilver. His location is unknown. It has been discovered that his bodyguards have been ensorcelled by Sinestra's ally, Tormuk the Necromancer. You must be alone with these two to break the spell, and discover Tyrillon's whereabouts.",
};

const elvenProspector = {
  number: 5,
  campaign: campaignID,
  title: "The Elven Prospector",
  description:
    "The Royal Prospector Tyrillon's fate is now known. He has been kidnapped by Sinestra's allies, and must be rescued form a mine complex to the east. He will then be able to lead you on in your quest for Moonsilver.",
};

const alchemistsLaboratory = {
  number: 6,
  campaign: campaignID,
  title: "The Alchemist's Laboratory",
  description:
    "The Chaos Sorcerer known as the High Alchemist controls the only known source of Moonsilver. He currently dwells in a stronghold which guards the passage to Sinestra's lands. You must find vials of true Moonsilver, with Tyrillon's aid, and venture on towards Sinestra's stronghold at Gilness Fen.",
};

const glinessFen = {
  number: 7,
  campaign: campaignID,
  title: "Gliness Fen",
  description:
    "The time has come to assault Sinestra's stronghold deep beneath the marshes of Gilness Fen. The gateways to the Realm of Reflection are said to lie in the deepest catacombs of the stronghold. You must fight your way down to these gateways to save Millandriell.",
};

const cavernsOfSolace = {
  number: 8,
  campaign: campaignID,
  title: "The Caverns of Solace ",
  description:
    "Sinestra has been alerted to your presence in her lair. Her minions gather to oppose your every step. The closer you are to reaching Sinestra, the greater the danger to Millandriell. Speed is your ally now. Pass through her stronghold as swiftly as you can, for time is not on our side.",
};

const hiddenRealms = {
  number: 9,
  campaign: campaignID,
  title: "Hidden Realms",
  description:
    "You have reached the depths of Sinestra's fortress. Here lies the mirror doorway to the Realm of Reflection. You can use your supplies of Moonsilver to pass through the gates between realities.. Enter the Realm of reflection then, and find Millandriell. It is believed that hidden here is the ancient Elven Bow of Vindication, an item as revered as the sword known as the Avenger. You must also return the bow, though it may help you in defeating Sinestra and her plans.",
};

const epilogue = {
  number: 10,
  campaign: campaignID,
  title: "Epilogue",
  description:
    "My friends, a great celebration is about to be held in your honour. Queen Terrellia wishes to thank you personally for the rescue of Millandriell, princess of the Elven kingdoms, form a most horrible fate. Through your valiant efforts, peace and harmony have been restored to the Elven kingdoms. You have ended the treat to the Elves, and secured a great victory against Morcar and his plans. As a reward for returning the Elven Bow of Vindication, defeating Sinestra, and rescuing Millandriell, the Queen has ordered you each given a purse of some 500 Gold Crowns. But greater accolades await you. You are all to be entered into the pages of the Elven Book of Heroes, you names and deeds recorded to the end of the World. You will be amongst the most famous champions to have walked in the Elven lands. Your names will become synonymous with Heroism, valour and justice. Go now and enjoy the celebration, for soon other deeds will call for your talents.",
};

const quests = [
  intro,
  avengerReturns,
  onSacredGround,
  terrelliasMaze,
  tormuksGuests,
  elvenProspector,
  alchemistsLaboratory,
  glinessFen,
  cavernsOfSolace,
  hiddenRealms,
  epilogue,
];

export const mageOfTheMirror = {
  id: campaignID,
  display: "The Mage of the Mirror",
  quests,
};
