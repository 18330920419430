export const listHeroes = /* GraphQL */ `
  query ListHeroes(
    $filter: ModelHeroFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeroes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        displayName
        character
        gold
        campaign
        completedQuest {
          items {
            id
            heroId
            number
            campaign
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
