import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const TabPanelContainer = styled.div``;

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </TabPanelContainer>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
