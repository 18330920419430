import * as React from "react";
import HeroListItem from "./HeroListItem";

export const HeroListItems = ({ heroes }) =>
  heroes.map((hero) => {
    const { id, character, name, gold } = hero;

    return (
      <HeroListItem
        key={id}
        character={character}
        id={id}
        name={name}
        gold={gold}
      />
    );
  });

export default HeroListItems;
