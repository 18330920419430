import { API, graphqlOperation } from "aws-amplify";
import { useDispatch } from "react-redux";

import {
  createCompletedQuest,
  deleteCompletedQuest,
} from "../model/questMutations";
import { updatedHeroCompletedQuest } from "@entities/heroes/model/heroesSlice";

function useQuestActions() {
  const dispatch = useDispatch();

  const addHeroCompletedQuest = async (quest) => {
    try {
      if (!quest?.heroId) return;
      const response = await API.graphql(
        graphqlOperation(createCompletedQuest, {
          input: {
            heroId: quest.heroId,
            number: quest.number,
            campaign: quest.campaign,
          },
        })
      );

      dispatch(
        updatedHeroCompletedQuest(
          response?.data?.createCompletedQuest?.hero?.completedQuest
        )
      );
    } catch (err) {
      console.log("error creating hero completed quest:", err);
    }
  };

  const deleteHeroCompletedQuest = async (completedQuestId) => {
    try {
      if (!completedQuestId) return;
      const response = await API.graphql(
        graphqlOperation(deleteCompletedQuest, {
          input: { id: completedQuestId },
        })
      );
      dispatch(
        updatedHeroCompletedQuest(
          response?.data?.deleteCompletedQuest?.hero?.completedQuest
        )
      );
    } catch (err) {
      console.log("error deleting hero completed quest:", err);
    }
  };

  return {
    addHeroCompletedQuest,
    deleteHeroCompletedQuest,
  };
}

export default useQuestActions;
