import { weapons } from "./weapons";

const barbarianEquipment = [{ ...weapons.broadsword, equipmentSlot: 0 }];
const dwarfEquipment = [{ ...weapons.shortsword, equipmentSlot: 0 }];
const elfEquipment = [{ ...weapons.shortsword, equipmentSlot: 0 }];
const wizardEquipment = [{ ...weapons.dagger, equipmentSlot: 0 }];

export const equipmentClass = {
  BARBARIAN: barbarianEquipment,
  DWARF: dwarfEquipment,
  ELF: elfEquipment,
  WIZARD: wizardEquipment,
};

export const weaponSlots = 3;
export const armorSlots = 6;
export const inventorySlots = 9;
