import * as React from "react";
import styled from "styled-components";
import { Button } from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete";
import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import { ShieldIcon } from "@entities/equipment/ui/ShieldIcon";
import { WeaponIcon } from "@entities/equipment/ui/WeaponIcon";
import { SpellsIcon } from "@entities/equipment/ui/SpellsIcon";
import { InventoryIcon } from "@entities/heroes/ui/InventoryIcon";

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &&& {
    margin-bottom: 10px;
  }
`;

const InventoryIndicator = styled.div`
  position: absolute;
  display: flex;
  background: white;
  bottom: 4px;
  right: 4px;
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  border: 2px solid grey;
  border-radius: 50%;
`;

const DeleteIndicator = styled.div`
  position: absolute;
  display: flex;
  background: white;
  top: 4px;
  right: 14px;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  border: 2px solid #5d4037;
  border-radius: 50%;
`;

const equipmentButtonHeight = 255;
const equipmentButtonWidth = 165;
const zoomEquipmentButtonHeight = 345;
const zoomEquipmentButtonWidth = 255;

// The implementation of this styled component uses the transient prop of $isSelectable
// to avoid a console error regrading invalid props for the DOM element.
// See transient props functionality of styled components package
const CardButtonStyled = styled(Button)`
  height: ${(props) =>
    props?.$zoom === true
      ? zoomEquipmentButtonHeight
      : equipmentButtonHeight}px;
  width: ${(props) =>
    props?.$zoom === true ? zoomEquipmentButtonWidth : equipmentButtonWidth}px;
  &&& {
    min-width: 40px;
    border-color: ${(props) =>
      props.$isSelectable && props.$isSelected && !props.image && "#388e3c"};
  }
`;

const EquipmentImage = styled.img`
  opacity: ${(props) => props.isSelectable && !props.isSelected && "50%"};
  height: ${(props) =>
    props?.$zoom === true
      ? zoomEquipmentButtonHeight
      : equipmentButtonHeight}px;
  width: ${(props) =>
    props?.$zoom === true ? zoomEquipmentButtonWidth : equipmentButtonWidth}px;
`;

const CardButton = ({
  type,
  onClick,
  onDeleteClick = null,
  name,
  imageURL,
  showCard,
  showInventoryIcon = false,
  isSelectable = false,
  selected = true,
  zoom = false,
}) => (
  <ButtonContainer item>
    <CardButtonStyled
      variant={!imageURL ? "outlined" : "text"}
      onClick={onClick}
      $isSelected={selected}
      $isSelectable={false}
      $zoom={zoom}
      color="secondary"
    >
      {imageURL && (
        <EquipmentImage
          src={imageURL}
          alt={name}
          isSelected={selected}
          isSelectable={isSelectable}
          $zoom={zoom}
        />
      )}
      {!showCard && type === equipmentTypes.WEAPON && (
        <WeaponIcon fontSize="large" />
      )}
      {!showCard && type === equipmentTypes.ARMOR && (
        <ShieldIcon fontSize="large" />
      )}
      {!showCard && type === equipmentTypes.SPELL && (
        <SpellsIcon fontSize="large" />
      )}
      {!showCard && type === equipmentTypes.INVENTORY && (
        <InventoryIcon fontSize="large" />
      )}
    </CardButtonStyled>
    {showInventoryIcon ? (
      <InventoryIndicator>
        <InventoryIcon color="action" fontSize="small" />
      </InventoryIndicator>
    ) : null}
    {onDeleteClick !== null ? (
      <DeleteIndicator>
        <DeleteIcon color="error" fontSize="medium" onClick={onDeleteClick} />
      </DeleteIndicator>
    ) : null}
  </ButtonContainer>
);

export default CardButton;
