export const equipmentTypes = {
  WEAPON: "WEAPON",
  ARMOR: "ARMOR",
  SPELL: "SPELL",
  POTION: "POTION",
  ITEM: "ITEM",
};

export const spellTypes = {
  FIRE: "FIRE",
  AIR: "AIR",
  EARTH: "EARTH",
  WATER: "WATER",
};

export const equipmentSubTypes = {
  ...spellTypes,
};
