import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";

import { CampaignIcon } from "@entities/campaign/ui/CampaignIcon";
import { StoreIcon } from "@entities/store/ui/StoreIcon";
import { CharacterIcon } from "@entities/characters/ui/CharacterIcon";

import { selectedHero } from "@entities/heroes/model/heroesSelectors";

// isActive is a transient prop. See transient props in styled components
const IconButtonStyled = styled(IconButton)`
  opacity: ${({ $isActive }) => ($isActive ? "100%" : "50%")};
  margin-left: 4px;
  &&& {
    border: ${({ $isActive }) =>
      $isActive ? "1px solid" : "1px solid transparent"};
    color: ${({ $isActive, theme }) =>
      $isActive ? theme.palette.secondary.main : "1px solid transparent"};
    border-radius: 40%;
    padding: 5px;
    margin-left: 4px;
  }
`;

const ToggleButtonContainer = styled.div``;

export const MenuToggle = ({
  tab,
  onCampaignClick,
  onStoreClick,
  onHeroClick,
}) => {
  const isStoreActive = tab === "store";
  const isCardActive = tab === "card";
  const isCampaignActive = tab === "campaign";
  const hero = useSelector(selectedHero);

  return (
    <ToggleButtonContainer>
      <IconButtonStyled onClick={onHeroClick} $isActive={isCardActive}>
        <CharacterIcon character={hero?.character} fontSize="large" />
      </IconButtonStyled>
      <IconButtonStyled onClick={onCampaignClick} $isActive={isCampaignActive}>
        <CampaignIcon fontSize="large" />
      </IconButtonStyled>
      <IconButtonStyled onClick={onStoreClick} $isActive={isStoreActive}>
        <StoreIcon fontSize="large" />
      </IconButtonStyled>
    </ToggleButtonContainer>
  );
};

export default MenuToggle;
