import { equipmentTypes } from "./equipmentTypes";

const toolkit = {
  name: "toolkit",
  cost: 250,
  description: "",
  type: equipmentTypes.ITEM,
  image: "toolkit",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Toolkit",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [],
};

export const items = {
  toolkit,
};
