import { createSelector } from "reselect";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import { campaigns } from "@entities/campaign/model/constants/campaigns";
import {
  selectedHeroCompletedQuests,
  selectedHeroCampaign,
} from "@entities/heroes/model/heroesSelectors";

export const selectedCampaign = createSelector(
  selectedHeroCampaign,
  (selected) => find(campaigns, { id: selected })
);

export const selectedHeroCompletedQuestsForCampaign = createSelector(
  selectedHeroCampaign,
  selectedHeroCompletedQuests,
  (campaign, quests) => {
    const completedQuestsForCampaign = quests.reduce((newList, q) => {
      if (q.campaign === campaign) newList.push(q);
      return newList;
    }, []);

    return sortBy(completedQuestsForCampaign, ["campaign", "number"]);
  }
);

export const selectedQuestsWithCompletion = createSelector(
  selectedCampaign,
  selectedHeroCompletedQuestsForCampaign,
  (campaign, completed) => {
    const campaignQuests = sortBy(campaign?.quests, ["number"]);

    const sortedCampaignQuests = campaignQuests.map((quest, index) => {
      const questToCompare = completed[index];
      if (
        questToCompare &&
        quest.campaign === questToCompare?.campaign &&
        quest.number === questToCompare?.number
      ) {
        return { ...quest, ...questToCompare };
      }
      return quest;
    });
    return sortedCampaignQuests;
  }
);
