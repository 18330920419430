export const characterTypes = {
  BARBARIAN: "BARBARIAN",
  ELF: "ELF",
  WIZARD: "WIZARD",
  DWARF: "DWARF",
};

export const characterAttributeTypes = {
  attack: "attack",
  defense: "defense",
  body: "body",
  mind: "mind",
  movement: "movement",
};

export default characterTypes;
