import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material/";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { routes } from "@shared/lib/routes";

const SignoutButtonContainer = styled.div``;

export const SignoutButton = withAuthenticator(({ signOut }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate(routes.root);
  };

  return (
    <SignoutButtonContainer>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleSignOut}
      >
        Sign out
      </Button>
    </SignoutButtonContainer>
  );
});

export default withAuthenticator(SignoutButton);
