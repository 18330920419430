import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";

import {
  TabPanelContainer,
  TabsContainer,
  a11yProps,
  TabPanel,
} from "@shared/ui/Tabs";
import { SpellsIcon } from "@entities/equipment/ui/SpellsIcon";
import { InventoryIcon } from "@entities/heroes/ui/InventoryIcon";
import { ShieldIcon } from "@entities/equipment/ui/ShieldIcon";
import { WeaponIcon } from "@entities/equipment/ui/WeaponIcon";
import {
  weaponSlots,
  armorSlots,
} from "@entities/characters/model/characterClasses";
import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import { characterTypes } from "@entities/characters/model/characterTypes";
import {
  selectedHero,
  selectedHeroArmor,
  selectedHeroWeapons,
} from "@entities/heroes/model/heroesSelectors";

// TODO: Clean up
import { HeroSpellsSection } from "./HeroSpellsSection";
import HeroEquipmentSection from "./HeroEquipmentSection";
import HeroInventorySection from "./HeroInventorySection";

const HeroInventoryContainer = styled.div``;

export const HeroEquipment = () => {
  const weapons = useSelector(selectedHeroWeapons);
  const armor = useSelector(selectedHeroArmor);
  const theme = useTheme();
  const hero = useSelector(selectedHero);
  const [value, setValue] = useState(0);
  if (!hero) return null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { character } = hero;
  const showSpells =
    character === characterTypes.ELF || character === characterTypes.WIZARD;

  return (
    <>
      <HeroInventoryContainer
        container
        item
        spacing={0.5}
        alignItems="space-between"
        justifyContent="center"
        direction="column"
      >
        <TabsContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab
              {...a11yProps(0)}
              icon={
                <>
                  <WeaponIcon fontSize="large" />
                </>
              }
              aria-label="weapons"
            />
            <Tab
              {...a11yProps(1)}
              icon={
                <>
                  <ShieldIcon fontSize="large" />
                </>
              }
              aria-label="armor"
            />
            <Tab
              {...a11yProps(2)}
              icon={
                <>
                  <InventoryIcon fontSize="large" />
                </>
              }
              aria-label="inventory"
            />
            {showSpells && (
              <Tab
                {...a11yProps(3)}
                icon={<SpellsIcon fontSize="large" />}
                aria-label="spells"
                disabled={character !== "WIZARD" && character !== "ELF"}
              />
            )}
          </Tabs>
        </TabsContainer>
        <TabPanelContainer heightAdjustment="280px">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <HeroEquipmentSection
              items={weapons}
              itemSlots={weaponSlots}
              equipmentType={equipmentTypes.WEAPON}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <HeroEquipmentSection
              items={armor}
              itemSlots={armorSlots}
              equipmentType={equipmentTypes.ARMOR}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <HeroInventorySection />
          </TabPanel>
          {showSpells && (
            <TabPanel value={value} index={3} dir={theme.direction}>
              <HeroSpellsSection />
            </TabPanel>
          )}
        </TabPanelContainer>
      </HeroInventoryContainer>
    </>
  );
};

export default HeroEquipment;
