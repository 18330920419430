import { baseGame } from "./baseGame";
import { kellarsKeep } from "./kellarsKeep";
import { returnOfTheWitchLord } from "./returnOfTheWitchLord";
import { frozenHorror } from "./frozenHorror";
import { mageOfTheMirror } from "./mageOfTheMirror";

export const campaigns = [
  baseGame,
  kellarsKeep,
  returnOfTheWitchLord,
  frozenHorror,
  mageOfTheMirror,
];

export const campaignIds = campaigns.reduce((campaignIdList, campaign) => {
  return { ...campaignIdList, [campaign.id]: campaign.id };
}, {});
