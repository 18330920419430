import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import { useDispatch } from "react-redux";

import CardButton from "@shared/ui/Cards/CardButton";
import { CardsContainer } from "@shared/ui/Cards/Cards.styled";
import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import { updateModal } from "@entities/modal/model/modalSlice";

export const HeroEquipmentSection = ({ items, itemSlots, equipmentType }) => {
  const dispatch = useDispatch();

  // set equipment to specific equipment slots based on slots available for various equipment types
  const itemButtons = [];
  const onItemClick = (item, equipmentSlot, type) => {
    dispatch(
      updateModal({
        name: "equipItems",
        equipmentType: type,
        equippedItem: item || null,
        itemToEquip: item || null,
        equipmentSlot,
      })
    );
  };

  for (let equipmentSlot = 0; equipmentSlot <= itemSlots - 1; equipmentSlot++) {
    const foundEquippedItem = find(items, { equipmentSlot });
    if (foundEquippedItem) {
      const { id, type, name, image, backImage, isExhausted } =
        foundEquippedItem;
      const imageUrl = `/${type.toLowerCase()}/${image}.jpeg`;
      const backImageUrl = `/common/${backImage}.jpeg`;
      const imageToDisplay = isExhausted === true ? backImageUrl : imageUrl;

      itemButtons.push(
        <CardButton
          key={id}
          type={type}
          imageURL={imageToDisplay}
          name={name}
          showCard={true}
          onClick={() => onItemClick(foundEquippedItem, equipmentSlot, type)}
        />
      );
    } else {
      itemButtons.push(
        <CardButton
          key={equipmentSlot}
          type={equipmentType}
          showCard={false}
          onClick={() => onItemClick(null, equipmentSlot, equipmentType)}
        />
      );
    }
  }
  return <CardsContainer>{itemButtons}</CardsContainer>;
};

HeroEquipmentSection.propTypes = {
  items: PropTypes.array,
  itemSlots: PropTypes.number,
  equipmentType: PropTypes.oneOf(Object.values(equipmentTypes)),
};

export default HeroEquipmentSection;
