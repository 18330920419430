import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material/";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";

import TabPanel from "@shared/ui/Tabs/TabPanel";
import {
  TabsContainer,
  TabPanelContainer,
  a11yProps,
} from "@shared/ui/Tabs/TabsCommon";
import CardsSection from "@shared/ui/Cards/CardsSection";
import { ArtifactIcon } from "@entities/equipment/ui/ArtifactIcon";
import { TreasureIcon } from "@entities/equipment/ui/TreasureIcon";
import { CampaignIcon } from "@entities/campaign/ui/CampaignIcon";
import { MonsterIcon } from "@entities/monsters/ui/MonsterIcon";
import {
  selectedHero,
  selectedHeroEquipmentNames,
} from "@entities/heroes/model/heroesSelectors";
import HeroProfile from "@entities/heroes/ui/hero-profile/HeroProfile";
import { updateModal } from "@entities/modal/model/modalSlice";
import { monsterListForHeroCampaign } from "@features/campaign/view-monsters/monsterSelectors";
import { treasureListForHeroCampaign } from "@features/campaign/view-treasure-items/treasureSelectors";
import { artifactsListForHeroCampaign } from "@features/campaign/view-artifact-items/artifactSelectors";
import CampaignQuests from "./CampaignQuests";
// TODO: move this modal to features folder

const InventoryContainer = styled(Grid)``;

export const Campaign = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hero = useSelector(selectedHero);
  const heroEquipmentNames = useSelector(selectedHeroEquipmentNames);
  const artifactsList = useSelector(artifactsListForHeroCampaign);
  const treasureList = useSelector(treasureListForHeroCampaign);
  const monsterList = useSelector(monsterListForHeroCampaign);

  const [value, setValue] = useState(0);

  if (!hero) return null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleItemClick = (item) => {
    dispatch(
      updateModal({
        name: "acquireEquipmentModal",
        itemToAcquire: item,
      })
    );
  };

  const handleMonsterClick = (monster) => {
    dispatch(
      updateModal({
        name: "viewMonster",
        monster,
      })
    );
  };

  return (
    <>
      <HeroProfile />
      <InventoryContainer
        container
        item
        spacing={0.5}
        alignItems="space-between"
        justifyContent="center"
        direction="column"
        // wrap="wrap"
      >
        <TabsContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
          >
            <Tab
              {...a11yProps(0)}
              icon={<CampaignIcon fontSize="large" />}
              aria-label="monsters"
            />
            <Tab
              {...a11yProps(1)}
              icon={<MonsterIcon fontSize="large" />}
              aria-label="monsters"
            />
            <Tab
              {...a11yProps(2)}
              icon={<TreasureIcon fontSize="large" />}
              aria-label="treasure"
            />

            <Tab
              {...a11yProps(3)}
              icon={<ArtifactIcon fontSize="large" />}
              aria-label="artifacts"
            />
          </Tabs>
        </TabsContainer>
        <TabPanelContainer heightAdjustment="130px">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CampaignQuests />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <CardsSection
              items={monsterList}
              onItemClick={handleMonsterClick}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <CardsSection
              equippedItemNames={heroEquipmentNames}
              items={treasureList}
              onItemClick={handleItemClick}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <CardsSection
              equippedItemNames={heroEquipmentNames}
              items={artifactsList}
              onItemClick={handleItemClick}
            />
          </TabPanel>
        </TabPanelContainer>
      </InventoryContainer>
    </>
  );
};

export default Campaign;
