import { Button } from "@mui/material";

export const PrimaryButton = ({ disabled, label, onClick }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default PrimaryButton;
