import { createSelector } from "reselect";
import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import { baseGame } from "@entities/campaign/model/constants";
import sortBy from "lodash/sortBy";
export const filterEquippedItems = (items) =>
  items.filter((item) => item.equipmentSlot !== null);

export const filterUnequippedItems = (items) =>
  items.filter((item) => item.equipmentSlot === null);

export const heroesRoot = (state) => state.heroes;
export const isLoading = createSelector(heroesRoot, (h) => h.isLoading);
export const heroesList = createSelector(heroesRoot, (h) => h.data);
export const heroEdits = createSelector(heroesRoot, (h) => h.edits);
export const selectedHero = createSelector(heroesRoot, (h) => h?.selected);
export const selectedHeroId = createSelector(heroesRoot, (h) => h.selected?.id);

export const selectedHeroCampaign = createSelector(
  selectedHero,
  (h) => h?.campaign || baseGame.id
);

export const selectedHeroCompletedQuests = createSelector(selectedHero, (h) =>
  sortBy(h.completedQuest.items, ["campaign", "number"])
);

export const editedHero = createSelector(
  selectedHero,
  heroEdits,
  (selected, edits) => ({
    ...selected,
    ...edits,
  })
);

export const selectedEditedHeroSpells = createSelector(editedHero, (hero) => {
  if (!hero?.spells) return [];
  return hero?.spells?.items;
});

export const selectedHeroEquipment = createSelector(editedHero, (hero) => {
  if (!hero?.equipment?.items) return [];
  return hero?.equipment?.items;
});

export const selectedHeroEquipmentNames = createSelector(
  selectedHeroEquipment,
  (equipment) =>
    equipment.reduce((nameList, item) => {
      nameList.push(item.name);
      return nameList;
    }, [])
);

export const selectedHeroWeapons = createSelector(
  selectedHeroEquipment,
  (heroInventory) => {
    const filteredItems = heroInventory.filter(
      (equipment) => equipment.type === equipmentTypes.WEAPON
    );
    const sortedItems = sortBy(filteredItems, ["equipmentSlot"]);
    return sortedItems;
  }
);

export const selectedHeroArmor = createSelector(
  selectedHeroEquipment,
  (heroInventory) => {
    return heroInventory.filter(
      (equipment) => equipment.type === equipmentTypes.ARMOR
    );
  }
);

export const selectedHeroSpells = createSelector(
  selectedHeroEquipment,
  (equipment) => {
    return equipment.filter((e) => e.type === equipmentTypes.SPELL);
  }
);

export const selectedHeroSpellTypes = createSelector(
  selectedHeroSpells,
  (spells) => {
    // Use set to enforce uniqueness and then convert back to array
    const spellTypeList = spells.reduce((spellTypeList, spell) => {
      spellTypeList.add(spell?.subType);
      return spellTypeList;
    }, new Set());
    return Array.from(spellTypeList);
  }
);

export const selectedHeroPotionsAndTools = createSelector(
  selectedHeroEquipment,
  (equipment) => {
    return equipment.filter(
      (e) => e.type === equipmentTypes.POTION || e.type === equipmentTypes.ITEM
    );
  }
);
