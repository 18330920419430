import React from "react";
import PropTypes from "prop-types";
import indexOf from "lodash/indexOf";
import CardButton from "./CardButton";

export const CardList = ({ equippedItemNames, heroGold, items, onItemClick }) =>
  items.map((item) => {
    const { type, name, image, cost } = item;
    const isEquipmentOwned = Boolean(
      indexOf(equippedItemNames, item?.name) >= 0
    );
    const canAffordItem = isNaN(cost) ? true : Number(cost) <= Number(heroGold);

    return (
      <CardButton
        key={name}
        type={type}
        imageURL={`/${type.toLowerCase()}/${image}.jpeg`}
        name={name}
        showCard={true}
        onClick={() => {
          onItemClick(item);
        }}
        isSelectable={true}
        selected={canAffordItem}
        showInventoryIcon={isEquipmentOwned}
      />
    );
  });

CardList.propTypes = {
  equippedItemNames: PropTypes.array,
  heroGold: PropTypes.number,
  items: PropTypes.array,
  onItemcClick: PropTypes.func,
};

export default CardList;
