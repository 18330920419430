import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import {
  equipmentTypes,
  spellTypes,
} from "@entities/equipment/model/equipmentTypes";
import { spellSlots } from "@entities/characters/model/characterClasses";
import {
  selectedHero,
  selectedHeroSpells,
  selectedHeroSpellTypes,
} from "@entities/heroes/model/heroesSelectors";
import { updateModal } from "@entities/modal/model/modalSlice";

// TODO: Clean up
import HeroEquipmentSection from "./HeroEquipmentSection";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
`;

export const HeroSpellsSection = () => {
  const dispatch = useDispatch();
  const spells = useSelector(selectedHeroSpells);
  const equippedSpellTypes = useSelector(selectedHeroSpellTypes);
  const hero = useSelector(selectedHero);
  if (!hero) return null;

  const handleSpellClick = async (item) => {
    dispatch(
      updateModal({
        name: "useItemModal",
        itemToUse: item,
      })
    );
  };

  const handleConfigureSpellsClick = () => {
    const selectedSpellTypes = equippedSpellTypes.reduce(
      (selectedTypes, spellType) => {
        selectedTypes[spellType] = true;
        return selectedTypes;
      },
      {
        [spellTypes.FIRE]: false,
        [spellTypes.AIR]: false,
        [spellTypes.WATER]: false,
        [spellTypes.EARTH]: false,
      }
    );
    dispatch(
      updateModal({
        name: "configureSpellType",
        selectedSpellTypes,
      })
    );
  };

  const { character } = hero;

  return (
    <>
      <ButtonContainer>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<SettingsIcon />}
          onClick={handleConfigureSpellsClick}
        >
          Spells
        </Button>
      </ButtonContainer>
      <HeroEquipmentSection
        items={spells}
        itemSlots={spellSlots[character]}
        onItemClick={handleSpellClick}
        equipmentType={equipmentTypes.SPELL}
      />
    </>
  );
};

export default HeroSpellsSection;
