import { characterTypes } from "@entities/characters/model/characterTypes";
import { equipmentTypes, equipmentSubTypes } from "./equipmentTypes";

const ballOfFlame = {
  name: "ballOfFlame",
  displayName: "Ball of Flame",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.FIRE,
  image: "ball-of-flame",
  backImage: "fire-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const fireOfWrath = {
  name: "fireOfWrath",
  displayName: "Fire of Wrath",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.FIRE,
  image: "fire-of-wrath",
  backImage: "fire-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const courage = {
  name: "courage",
  displayName: "Courage",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.FIRE,
  image: "courage",
  backImage: "fire-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const genie = {
  name: "genie",
  displayName: "Genie",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.AIR,
  image: "genie",
  backImage: "air-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const tempest = {
  name: "tempest",
  displayName: "Tempest",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.AIR,
  image: "tempest",
  backImage: "air-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const swiftWind = {
  name: "swiftWind",
  displayName: "Swift Wind",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.AIR,
  image: "swift-wind",
  backImage: "air-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const sleep = {
  name: "sleep",
  displayName: "Sleep",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.WATER,
  image: "sleep",
  backImage: "water-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const waterOfHealing = {
  name: "waterOfHealing",
  displayName: "Water of Healing",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.WATER,
  image: "water-of-healing",
  backImage: "water-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const veilOfMist = {
  name: "veilOfMist",
  displayName: "Veil of Mist",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.WATER,
  image: "veil-of-mist",
  backImage: "water-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const rockSkin = {
  name: "rockSkin",
  displayName: "Rock Skin",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.EARTH,
  image: "rock-skin",
  backImage: "earth-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const passThroughRock = {
  name: "passThroughRock",
  displayName: "Pass Through Rock",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.EARTH,
  image: "pass-through-rock",
  backImage: "earth-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const healBody = {
  name: "healBody",
  displayName: "Heal Body",
  campaign: "baseGame",
  cost: null,
  description: "",
  type: equipmentTypes.SPELL,
  subType: equipmentSubTypes.EARTH,
  image: "heal-body",
  backImage: "earth-back",
  isArtifact: false,
  isExhausted: false,
  isExhaustable: true,
  isConsumable: false,
  characterRestrictions: [characterTypes.dwarf, characterTypes.barbarian],
  modifiers: [],
};

const fireSpells = [ballOfFlame, fireOfWrath, courage];
const airSpells = [genie, tempest, swiftWind];
const waterSpells = [sleep, veilOfMist, waterOfHealing];
const earthSpells = [rockSkin, passThroughRock, healBody];

export const spellTypeBackImages = {
  FIRE: "fire-back",
  AIR: "air-back",
  EARTH: "earth-back",
  WATER: "water-back",
};

export const spells = {
  [equipmentSubTypes.FIRE]: fireSpells,
  [equipmentSubTypes.AIR]: airSpells,
  [equipmentSubTypes.WATER]: waterSpells,
  [equipmentSubTypes.EARTH]: earthSpells,
};
