import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { WeaponIcon } from "@entities/equipment/ui/WeaponIcon";
import { ShieldIcon } from "@entities/equipment/ui/ShieldIcon";
import { HeartIcon } from "@entities/heroes/ui/HeartIcon";
import { MindIcon } from "@entities/heroes/ui/MindIcon";

import { characterClasses } from "@entities/characters/model/characterClasses";
import { selectedHero } from "@entities/heroes/model/heroesSelectors";
import { updateModal } from "@entities/modal/model/modalSlice";
import UpdateAttributeModal from "@widgets/hero/UpdateAttributeModal";

import HeroAttribute from "./HeroAttribute";

const HeroAttributesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const HeroAttributes = () => {
  const dispatch = useDispatch();
  const hero = useSelector(selectedHero);
  const [isOpen, setOpen] = useState(false);
  const [attribute, setAttributeToUpdate] = useState();

  const { attack, defense, body, mind, character } = hero;
  const heroBaseClass = characterClasses[character];

  const handleAttributeClick = (selectedAttribute, value) => {
    dispatch(
      updateModal({
        name: "updateAttribute",
        attribute: selectedAttribute,
        currentValue: value,
        modifier: "",
      })
    );
    setAttributeToUpdate(selectedAttribute);
    setOpen(true);
  };

  const handleAttackClick = () => {
    handleAttributeClick("attack", attack);
  };
  const handleDefenseClick = () => {
    handleAttributeClick("defense", defense);
  };
  const handleBodyClick = () => {
    handleAttributeClick("body", body);
  };
  const handleMindClick = () => {
    handleAttributeClick("mind", mind);
  };
  return (
    <>
      <UpdateAttributeModal
        attribute={attribute}
        open={isOpen}
        setOpen={setOpen}
      ></UpdateAttributeModal>
      <HeroAttributesContainer>
        <HeroAttribute
          name="Attack"
          attribute="attack"
          value={attack}
          baseValue={heroBaseClass.attack}
          icon={<WeaponIcon fontSize="small" />}
          onClick={handleAttackClick}
        />
        <HeroAttribute
          name="Defend"
          attribute="defense"
          value={defense}
          baseValue={heroBaseClass.defense}
          icon={<ShieldIcon fontSize="small" />}
          onClick={handleDefenseClick}
        />
        <HeroAttribute
          name="Body Pts"
          attribute="body"
          value={body}
          baseValue={heroBaseClass.body}
          icon={<HeartIcon fontSize="small" />}
          onClick={handleBodyClick}
        />
        <HeroAttribute
          name="Mind Pts"
          attribute="mind"
          value={mind}
          baseValue={heroBaseClass.mind}
          icon={<MindIcon fontSize="small" />}
          onClick={handleMindClick}
        />
      </HeroAttributesContainer>
    </>
  );
};

export default HeroAttributes;
