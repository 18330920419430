import React from "react";

import CampaignSelectMenu from "./CampaignSelectMenu";
import QuestList from "./QuestList";

export const CampaignQuests = () => {
  return (
    <>
      <CampaignSelectMenu />
      <QuestList />
    </>
  );
};

export default CampaignQuests;
