import { Box, styled as styledMU } from "@mui/material/";

export const ModalContainer = styledMU(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "700px",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "0.5rem",
  backgroundColor: theme.palette.background.paper, // var(--mui-palette-primary-main)
  textAlign: "center",
}));

export default ModalContainer;
