/* src/App.js */
import React from "react";
import styled from "styled-components";
import {
  Typography,
  TextField,
  Paper,
  Modal,
  CircularProgress,
} from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import { selectedHeroId } from "@entities/heroes/model/heroesSelectors";
import {
  updateGold,
  clearModal,
  updateModal,
} from "@entities/modal/model/modalSlice";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";
import { modal } from "@entities/modal/model/modalSelectors";

const CalculationContainer = styled.div`
  display: flex;
  flex: row;
  justify-content: space-around;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const UpdateGoldModal = () => {
  const dispatch = useDispatch();
  const { updateHero } = useHeroAPI();
  const heroId = useSelector(selectedHeroId);

  const modalState = useSelector(modal);
  const handleClose = () => dispatch(clearModal());
  const isOpen = modalState?.name === "updateGold";

  const { currentGold, newGold, isLoading } = modalState;
  const futureGold = Number(currentGold) + Number(newGold);
  const modifierColor =
    newGold > 0 ? "#388e3c" : newGold < 0 ? "#A30000" : undefined;

  const isGoldChanged = futureGold !== currentGold;

  const handleSaveClick = async () => {
    dispatch(updateModal({ isLoading: true }));
    await updateHero({ gold: futureGold, id: heroId });
    handleClose();
  };

  const handleOnChange = (e) => {
    const newGold = Number(e.target.value);
    dispatch(updateGold(newGold));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <CalculationContainer>
            <ValueContainer>
              <Typography variant="subtitle1">Current</Typography>
              <Typography variant="h4">{currentGold}</Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="h4" color={modifierColor}>
                {newGold < 0 ? " - " : " + "}
              </Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="subtitle1">New</Typography>
              <Typography variant="h4" color={modifierColor}>
                <TextField
                  id="outlined-number"
                  type="number"
                  size="small"
                  value={newGold}
                  onChange={handleOnChange}
                />
              </Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="h4" color={modifierColor}>
                =
              </Typography>
            </ValueContainer>
            <ValueContainer>
              <Typography variant="subtitle1">Future</Typography>
              <Typography variant="h4" color={modifierColor}>
                {futureGold}
              </Typography>
            </ValueContainer>
          </CalculationContainer>
          <ButtonsContainer>
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleSaveClick}
              disabled={isLoading || !isGoldChanged}
            >
              {isLoading ? <CircularProgress /> : "Save"}
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default UpdateGoldModal;
