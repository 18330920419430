import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectedHero } from "@entities/heroes/model/heroesSelectors";
import { CardsContainer } from "./Cards.styled";
import CardList from "./CardList";

export const CardsSection = ({ items, equippedItemNames, onItemClick }) => {
  const { gold } = useSelector(selectedHero);

  return (
    <CardsContainer>
      <CardList
        equippedItemNames={equippedItemNames}
        heroGold={gold}
        items={items}
        onItemClick={onItemClick}
      />
    </CardsContainer>
  );
};

CardsSection.propTypes = {
  equippedItems: PropTypes.array,
  itemSlots: PropTypes.number,
  onItemClick: PropTypes.func,
};

export default CardsSection;
