import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material/";
import { useNavigate } from "react-router-dom";

import { routes } from "@shared/lib/routes";

export const ViewHeroButton = ({ id }) => {
  const navigate = useNavigate();
  const handleViewClick = (event, element) => {
    navigate(`${routes.hero}/${id}/card`);
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={handleViewClick}
    >
      View
    </Button>
  );
};

ViewHeroButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ViewHeroButton;
