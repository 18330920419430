/* eslint-disable */

export const createHero = /* GraphQL */ `
  mutation CreateHero(
    $input: CreateHeroInput!
    $condition: ModelHeroConditionInput
  ) {
    createHero(input: $input, condition: $condition) {
      id
      owner
      name
      displayName
      character
      attack
      defense
      body
      mind
      gold
      campaign
      completedQuest {
        items {
          id
          heroId
          number
          campaign
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          heroId
          equipmentId
          name
          displayName
          description
          type
          subType
          image
          backImage
          cost
          equipmentSlot
          isArtifact
          isExhausted
          isExhaustable
          isConsumable
          characterRestrictions
          pairedEquipmentRestrictions
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateHero = /* GraphQL */ `
  mutation UpdateHero(
    $input: UpdateHeroInput!
    $condition: ModelHeroConditionInput
  ) {
    updateHero(input: $input, condition: $condition) {
      id
      owner
      name
      displayName
      character
      attack
      defense
      body
      mind
      gold
      campaign
      completedQuest {
        items {
          id
          heroId
          number
          campaign
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          heroId
          equipmentId
          name
          displayName
          description
          type
          subType
          image
          backImage
          cost
          equipmentSlot
          isArtifact
          isExhausted
          isExhaustable
          isConsumable
          characterRestrictions
          pairedEquipmentRestrictions
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteHero = /* GraphQL */ `
  mutation DeleteHero(
    $input: DeleteHeroInput!
    $condition: ModelHeroConditionInput
  ) {
    deleteHero(input: $input, condition: $condition) {
      id
      owner
      name
      displayName
      character
      attack
      defense
      body
      mind
      gold
      campaign
      completedQuest {
        items {
          id
          heroId
          number
          campaign
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          heroId
          equipmentId
          name
          displayName
          description
          type
          subType
          image
          backImage
          cost
          equipmentSlot
          isArtifact
          isExhausted
          isExhaustable
          isConsumable
          characterRestrictions
          pairedEquipmentRestrictions
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
