import React from "react";
import styled from "styled-components";

const LogoImg = styled.img`
  width: calc(100vw);
  max-width: 600px;
  padding: 2rem;
`;

export const HeroQuestLogo = () => {
  return <LogoImg src="/common/heroquest-logo.png" alt="heroquest logo" />;
};

export default HeroQuestLogo;
