/* eslint-disable */

export const createCompletedQuest = /* GraphQL */ `
  mutation CreateCompletedQuest(
    $input: CreateCompletedQuestInput!
    $condition: ModelCompletedQuestConditionInput
  ) {
    createCompletedQuest(input: $input, condition: $condition) {
      id
      heroId
      number
      campaign
      hero {
        campaign
        completedQuest {
          items {
            id
            heroId
            number
            campaign
          }
        }
      }
    }
  }
`;

export const updateCompletedQuest = /* GraphQL */ `
  mutation UpdateCompletedQuest(
    $input: UpdateCompletedQuestInput!
    $condition: ModelCompletedQuestConditionInput
  ) {
    updateCompletedQuest(input: $input, condition: $condition) {
      id
      heroId
      number
      campaign
      hero {
        campaign
        completedQuest {
          items {
            id
            heroId
            number
            campaign
          }
        }
      }
    }
  }
`;

export const deleteCompletedQuest = /* GraphQL */ `
  mutation DeleteCompletedQuest(
    $input: DeleteCompletedQuestInput!
    $condition: ModelCompletedQuestConditionInput
  ) {
    deleteCompletedQuest(input: $input, condition: $condition) {
      id
      heroId
      number
      campaign
      hero {
        campaign
        completedQuest {
          items {
            id
            heroId
            number
            campaign
          }
        }
      }
    }
  }
`;
