import concat from "lodash/concat";
import { characterClasses } from "@entities/characters/model/characterClasses";
import { spells } from "@entities/equipment/model/spells";

export const getBaseCharacterForSave = (character) => {
  const baseHero = characterClasses[character];
  const heroSaveSchema = {
    ...baseHero,
  };
  return heroSaveSchema;
};

export const getSpellsToAdd = (spellTypes) =>
  spellTypes.reduce((list, type) => {
    return concat(list, spells[type]);
  }, []);
