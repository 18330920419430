import { API, graphqlOperation, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import { getHero } from "../model/queries/hero";
import { listHeroes } from "../model/queries/heroes";
import {
  deleteHero as deleteHeroMutation,
  createHero as createHeroMutation,
  updateHero as updateHeroMutation,
} from "../model/mutations/hero";
import { createHeroEquipment as createHeroEquipmentMutation } from "@entities/equipment/model/heroEquipmentMutations";
import {
  createdHero,
  deletedHero,
  updatedHero,
} from "@entities/heroes/model/heroesSlice";
import { equipmentClass } from "@entities/equipment/model/equipmentConstants";
import { getBaseCharacterForSave } from "@entities/heroes/heroUtils";
import {
  fetchStart,
  fetchComplete,
  fetchSelectedStart,
  fetchSelectedComplete,
} from "../model/heroesSlice";

const useHeroAPI = () => {
  const dispatch = useDispatch();

  const fetchHeroes = async () => {
    try {
      dispatch(fetchStart());
      const heroesData = await API.graphql(graphqlOperation(listHeroes));
      dispatch(fetchComplete(heroesData));
    } catch (err) {
      console.log("error fetching heroes");
    }
  };

  const fetchHero = async (id) => {
    try {
      dispatch(fetchSelectedStart());
      const heroData = await API.graphql(graphqlOperation(getHero, { id }));
      dispatch(fetchSelectedComplete(heroData));
    } catch (err) {
      console.log("error fetching heroes");
    }
  };

  const deleteHero = async (id) => {
    try {
      if (!id) return;
      await API.graphql(
        graphqlOperation(deleteHeroMutation, {
          input: { id },
        })
      );
    } catch (err) {
      console.log("error deleting hero:", err);
    }
    dispatch(deletedHero({ id }));
  };

  const createHero = async (name, character) => {
    try {
      if (!name || !character) return;
      const hero = {
        ...getBaseCharacterForSave(character),
        name,
        owner: Auth.user.username,
      };
      const equipment = equipmentClass[character];

      const savedHero = await API.graphql(
        graphqlOperation(createHeroMutation, { input: hero })
      );
      const savedHeroId = savedHero?.data?.createHero?.id;
      if (equipment) {
        for (let i = 0; i < equipment.length; i++) {
          await API.graphql(
            graphqlOperation(createHeroEquipmentMutation, {
              input: { heroId: savedHeroId, ...equipment[i] },
            })
          );
        }
      }

      const getHeroResponse = await API.graphql(
        graphqlOperation(getHero, { id: savedHeroId })
      );
      const recentlyCreatedHero = getHeroResponse?.data?.getHero;
      dispatch(createdHero(recentlyCreatedHero));
    } catch (err) {
      console.log("error creating hero:", err);
    }
  };

  const updateHero = async ({ id, ...heroData }) => {
    try {
      const hero = await API.graphql(
        graphqlOperation(updateHeroMutation, {
          input: { id, ...heroData },
        })
      );
      dispatch(updatedHero(hero?.data?.updateHero));
    } catch (err) {
      console.log("error updating hero:", err);
    }
  };

  const updateHeroCampaign = async ({ heroId, campaign }) => {
    try {
      if (!heroId) throw Error("No valid heroId provided");
      updateHero({ id: heroId, campaign });
    } catch (err) {
      console.log("error creating hero completed quest:", err);
    }
  };

  return {
    fetchHeroes,
    fetchHero,
    createHero,
    deleteHero,
    updateHero,
    updateHeroCampaign,
  };
};

export default useHeroAPI;
