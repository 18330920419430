import React, { useState, useEffect } from "react";
import Quest from "@entities/campaign/ui/Quest";
import { useSelector } from "react-redux";
import findLast from "lodash/findLast";

import useQuestActions from "@entities/campaign/hooks/useQuestActions";
import { selectedHeroId } from "@entities/heroes/model/heroesSelectors";
import { selectedQuestsWithCompletion } from "@features/campaign/view-quests/questsSelectors";

export const QuestList = () => {
  const [expanded, setExpanded] = useState(false);
  const quests = useSelector(selectedQuestsWithCompletion);
  const heroId = useSelector(selectedHeroId);
  const { addHeroCompletedQuest, deleteHeroCompletedQuest } = useQuestActions();

  useEffect(() => {
    const lastCompletedQuest = findLast(quests, (quest) => Boolean(quest?.id));
    const defaultQuestToExpand = lastCompletedQuest
      ? lastCompletedQuest.number + 1
      : 1;
    setExpanded(defaultQuestToExpand);
  }, [quests]);

  const handleChange = (questNumber) => async () => {
    setExpanded(questNumber === expanded ? null : questNumber);
  };

  const handleMarkCompleteClick = (quest) => async () => {
    await addHeroCompletedQuest({ ...quest, heroId });
  };

  const handleMarkIncompleteClick = (quest) => async () => {
    await deleteHeroCompletedQuest(quest.id);
  };

  if (quests.length < 1) return null;
  const questListComponents = quests.reduce((questsToShow, quest, index) => {
    const previousQuest = quests[index - 1];
    const isPreviousQuestComplete = previousQuest?.id !== undefined;
    const shouldQuestBeVisible = isPreviousQuestComplete || quest.number === 0;
    const isNextQuestComplete = quests[index + 1]?.id;
    const isQuestComplete = quest.id;

    // only show mark incomplete for the
    const shouldMarkIncompleteButtonShow =
      isQuestComplete && !isNextQuestComplete;

    if (shouldQuestBeVisible) {
      questsToShow.push(
        <Quest
          key={quest.number}
          title={quest?.title}
          description={quest?.description}
          isComplete={quest.id}
          isExpanded={expanded === quest.number}
          onExpansionClick={handleChange(quest.number)}
          onMarkCompleteClick={handleMarkCompleteClick(quest)}
          onMarkIncompleteClick={
            shouldMarkIncompleteButtonShow
              ? handleMarkIncompleteClick(quest)
              : null
          }
        />
      );
    }
    return questsToShow;
  }, []);

  return <>{questListComponents}</>;
};

export default QuestList;
