import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearSelected } from "../../../model/heroesSlice";

import { routes } from "@shared/lib/routes";
const useHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const onBackClick = () => {
    dispatch(clearSelected());
    navigate(routes.heroes);
  };
  const onCampaignClick = () => {
    navigate(`${routes.hero}/${id}/campaign`);
  };
  const onHeroClick = () => {
    navigate(`${routes.hero}/${id}/card`);
  };
  const onStoreClick = (event, element) => {
    navigate(`${routes.hero}/${id}/store`);
  };

  return {
    onBackClick,
    onHeroClick,
    onStoreClick,
    onCampaignClick,
  };
};

export default useHeader;
