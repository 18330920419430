import React from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrimaryButton from "@shared/ui/Buttons/PrimaryButton";
import SecondaryButton from "@shared/ui/Buttons/SecondaryButton";

export const CompleteContainer = styled.div`
  display: inline-block;
  width: 80%;
  vertical-align: middle;
`;

export const IconContainer = styled.div`
  display: inline-block;
  width: 1em;
  margin-right: 1rem;
  vertical-align: middle;
  line-height: 1;
`;

export const QuestTitleContainer = styled.div`
  align-items: center;
  width: 100%;
`;

export const QuestCompleteIndicator = ({ isComplete }) => (
  <IconContainer>
    {isComplete ? (
      <CheckCircleIcon color="success" fontSize="small" />
    ) : (
      <CheckCircleIcon color="disabled" fontSize="small" />
    )}
  </IconContainer>
);

export const Quest = ({
  description,
  disabled,
  isComplete = false,
  isExpanded,
  onExpansionClick,
  onMarkCompleteClick,
  onMarkIncompleteClick,
  title,
}) => {
  return (
    <Accordion
      expanded={isExpanded}
      disableGutters={true}
      disabled={disabled}
      onChange={onExpansionClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <QuestTitleContainer>
          <QuestCompleteIndicator isComplete={isComplete} />
          <CompleteContainer>
            <Typography noWrap variant="h6">
              {title}
            </Typography>
          </CompleteContainer>
        </QuestTitleContainer>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1">{description}</Typography>
        <br />
        {isComplete && onMarkIncompleteClick !== null ? (
          <SecondaryButton
            label="Mark Incomplete"
            onClick={onMarkIncompleteClick}
          />
        ) : null}
        {!isComplete ? (
          <PrimaryButton label="Mark Compelete" onClick={onMarkCompleteClick} />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default Quest;
