import React from "react";
import { Typography } from "@mui/material/";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useMatch } from "react-router-dom";

import useHeader from "./useHeader";
import MenuToggle from "./MenuToggle";

export const Header = () => {
  const { onStoreClick, onBackClick, onHeroClick, onCampaignClick } =
    useHeader();
  const match = useMatch("/hero/:id/:tab");

  const { tab } = match.params;
  return (
    <AppBar position="sticky">
      <Toolbar>
        <Button
          onClick={onBackClick}
          variant="outlined"
          size="small"
          color="secondary"
          aria-label="menu"
        >
          Heroes List
        </Button>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <MenuToggle
          tab={tab}
          onCampaignClick={onCampaignClick}
          onStoreClick={onStoreClick}
          onHeroClick={onHeroClick}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
