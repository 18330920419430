/* src/App.js */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Modal, Paper, CircularProgress } from "@mui/material/";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";

import { deleteHeroModal } from "@entities/modal/model/modalSelectors";
import { updateModal, clearModal } from "@entities/modal/model/modalSlice";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";

const DeleteHeroModal = () => {
  const { deleteHero } = useHeroAPI();
  const dispatch = useDispatch();
  const modalState = useSelector(deleteHeroModal);

  const { isLoading, name, heroId } = modalState;
  const isOpen = name === "deleteHeroModal";

  async function handleDeleteHero(event) {
    dispatch(updateModal({ isLoading: true }));
    await deleteHero(heroId);
    dispatch(clearModal());
  }

  const handleClose = () => dispatch(clearModal());

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper eleveation={0}>
        <ModalContainer>
          <Typography variant="h6" textAlign="center">
            Are you sure you want to permanantely delete this character?
          </Typography>
          <ButtonsContainer>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleDeleteHero}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "Delete"}
            </ModalButton>
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default DeleteHeroModal;
