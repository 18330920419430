/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://344zjcylpvetrn44kklngfe5q4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qntkwxifszf5vaj2y2m7mrc7im",
    "aws_cognito_identity_pool_id": "us-east-1:b0305567-58d9-452d-a134-c263b32b2c5d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_X1yk3Frr0",
    "aws_user_pools_web_client_id": "1mvk2vvd87kt9qi2i0ui8psuug",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "heroquest2ecaff30565146e3a46f821bb493582d234827-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "heroquest-20230107101834-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://daoaa5oyhz7sy.cloudfront.net"
};


export default awsmobile;
