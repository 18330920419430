import { SvgIcon } from "@material-ui/core";

export const InventoryIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M256.23 29.742c-17.72 0-35.439 3.314-51.443 9.944-13.634 5.647-25.485 18.372-35.219 29.693A283.41 283.41 0 0 0 155.625 87h22.7c9.04-10.028 21.336-25.336 33.35-30.686 27.12-11.233 61.992-11.233 89.112 0 13.899 8.59 24.041 19.18 33.35 30.686h22.699a283.005 283.005 0 0 0-13.941-17.621c-9.734-11.32-21.585-24.046-35.22-29.693-16.003-6.63-33.725-9.944-51.445-9.944zM144.23 105c-16.23 2.136-38.662 23-39 39v.02c-.109 52.992 25.641 85.63 57.801 106.422 21.377 13.82 45.768 21.86 66.2 25.715V231h54v45.154c20.407-3.855 44.758-11.894 66.11-25.71C381.475 229.651 407.232 197 407.232 144c-.123-16-25.018-37.725-39-39zm-39.232 110.178L105.225 487h301.998l-.225-271.18c-12.145 21.258-29.246 37.681-47.877 49.737-25.077 16.226-52.67 25.127-75.89 29.017V329h-54v-34.424c-23.242-3.889-50.87-12.788-75.973-29.017-18.834-12.177-36.106-28.808-48.26-50.381zM247.23 249v62h18v-62zm-124 106h114v114h-114zm152 0h114v114h-114zm-134 18v20.203c14.631 21.212 27.883 29.797 39 29.797 11.118 0 24.37-8.585 39-29.795V373zm152 0v20.203c14.631 21.212 27.883 29.797 39 29.797 11.118 0 24.37-8.585 39-29.795V373zm-152 48.736V451h78v-29.264c-12.116 12.358-25.028 19.264-39 19.264-13.971 0-26.884-6.906-39-19.264zm152 0V451h78v-29.264c-12.116 12.358-25.028 19.264-39 19.264-13.971 0-26.884-6.906-39-19.264z" />
    </SvgIcon>
  );
};

export default InventoryIcon;
