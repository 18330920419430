import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material/";
import AddIcon from "@mui/icons-material/Add";
import { updateModal } from "@entities/modal/model/modalSlice";

const AddButton = styled(Button)`
  width: 100%;
`;

export const AddHeroButton = () => {
  const dispatch = useDispatch();

  const handleNewHeroClick = () => {
    dispatch(
      updateModal({
        name: "createHeroModal",
      })
    );
  };

  return (
    <>
      <AddButton
        variant="contained"
        onClick={handleNewHeroClick}
        startIcon={<AddIcon />}
        color="secondary"
      >
        New Hero
      </AddButton>
    </>
  );
};

export default AddHeroButton;
