import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Chip } from "@mui/material/";
import IconButton from "@mui/material/IconButton";

import { updateModal } from "@entities/modal/model/modalSlice";
import { selectedHero } from "@entities/heroes/model/heroesSelectors";
import { CoinIcon } from "@entities/heroes/ui/CoinIcon";
import UpdateGoldModal from "@widgets/hero/UpdateGoldModal";

const HeroProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
`;

const HeroGoldButton = styled(IconButton)`
  &&& {
    padding-right: 0;
  }
`;

export const HeroProfile = () => {
  const dispatch = useDispatch();
  const hero = useSelector(selectedHero);
  const { name, gold } = hero;
  if (!hero) return null;

  const handleGoldClick = () => {
    dispatch(
      updateModal({
        name: "updateGold",
        currentGold: gold,
        newGold: "",
      })
    );
  };

  return (
    <>
      <UpdateGoldModal />
      <HeroProfileContainer
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xlg={6}>
          <Typography variant="h6">{name}</Typography>
        </Grid>
        <Grid item xlg={6}>
          <HeroGoldButton onClick={handleGoldClick}>
            <Chip
              color="secondary"
              variant="default"
              icon={<CoinIcon />}
              label={gold}
            />
          </HeroGoldButton>
        </Grid>
      </HeroProfileContainer>
    </>
  );
};

HeroProfile.propTypes = {
  hero: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    gold: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
};

export default HeroProfile;
