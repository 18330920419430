/* src/App.js */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Modal } from "@mui/material/";

import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import CardButton from "@shared/ui/Cards/CardButton";
import { clearModal } from "@entities/modal/model/modalSlice";
import { viewMonsterModal } from "@entities/modal/model/modalSelectors";

export const ViewMonsterModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector(viewMonsterModal);
  const { name: modalName, monster } = modalState;

  const isOpen = modalName === "viewMonster";
  if (!monster) return null;

  const handleClose = () => dispatch(clearModal());

  const { image, type, name } = monster;
  const imageUrl = `/${type?.toLowerCase()}/${image}.jpeg`;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <CardButton
            key={name}
            imageURL={imageUrl}
            name={name}
            showCard={true}
            zoom={true}
          />
          <ButtonsContainer>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Close
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default ViewMonsterModal;
