import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import {
  characterTypes,
  characterAttributeTypes,
} from "@entities/characters/model/characterTypes";

const battleAxe = {
  name: "battleAxe",
  cost: 450,
  description:
    "This heavy, double-edged axe gives you the attack strength of 4 combat dice. You may not use a shield when using this weapon. May not be used by the wizard.",
  type: equipmentTypes.WEAPON,
  image: "battle-axe",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Battle Axe",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 4,
    },
  ],
};

const broadsword = {
  name: "broadsword",
  cost: 250,
  description:
    "This wideblade gives you the attack strength of 3 combat dice. May not be used by the wizard.",
  type: equipmentTypes.WEAPON,
  image: "broadsword",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Broadsword",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 3,
    },
  ],
};

const crossbow = {
  name: "crossbow",
  cost: 350,
  description:
    "This long-range weapon gives you the attack strength of 3 combat dice. You may fire at any monster that you can see. However, you cannot fire at a monster that is adjacent to you. You have an unlimited supply of arrows. May not be used by the wizard.",
  type: equipmentTypes.WEAPON,
  image: "crossbow",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Crossbow",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 3,
    },
  ],
};

const dagger = {
  name: "dagger",
  cost: 25,
  description:
    "This sharp knife gives you the attack strength of 1 combat die. A dagger can also be thrown at any monster you can see but it is lost once it is thrown.",
  type: equipmentTypes.WEAPON,
  image: "dagger",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Dagger",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 1,
    },
  ],
};

const fortunesLongsword = {
  name: "fortunesLongsword",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "fortunes-longsword",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Fortune's Longsword",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 3,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "Fortunes Longsword: Once per quest reroll 1 attack die",
      value: null,
    },
  ],
};

const handAxe = {
  name: "handaxe",
  cost: 200,
  description:
    "This handaxe allows you to roll 2 Attack dice. It can also be thrown at any monster in your line of sight but is lost once it is thrown.",
  type: equipmentTypes.WEAPON,
  image: "handaxe",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Handaxe",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 2,
    },
  ],
};

const longsword = {
  name: "longsword",
  cost: 350,
  description:
    "This long blade gives you the attack strength of 3 combat dice. Because of its length, the longsword enables you to attack diagonally. May not be used by the wizard.",
  type: equipmentTypes.WEAPON,
  image: "longsword",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Longsword",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 3,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "Longsword: Attack diagonally",
      value: null,
    },
  ],
};

const magicalThrowingDagger = {
  name: "magicalThrowingDagger",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "magical-throwing-dagger",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Magical Throwing Dagger",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification:
        "Throwing Dagger: Always inflicts 1 body point of damage when thrown",
      value: null,
    },
  ],
};

const orcsBane = {
  name: "orcsBane",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "orcs-bane",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Orc's Bane",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 2,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "Orc's Bane: Attack twice against orcs",
      value: null,
    },
  ],
};

const phantomBlade = {
  name: "phantomBlade",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "phantom-blade",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Phantom Blade",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 1,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "Phantom Blade: Once per quest, target may not defend",
      value: 0,
    },
  ],
};

const rodOfTelekinesis = {
  name: "rodOfTelekinesis",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "rod-of-telekinesis",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "frozenHorror",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification:
        "Rod of Telekinesis: Once per quest, monster misses next turn unless 6 is rolled with 1 red die per mind point",
      value: null,
    },
  ],
};

const shortsword = {
  name: "shortsword",
  cost: 150,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "shortsword",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Shortsword",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 2,
    },
  ],
};

const spiritBlade = {
  name: "spiritBlade",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "spirit-blade",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Spirit Blade",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 3,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification:
        "Spirit Blade: Roll 4 when attacking undead (skeleton, zombie, or mummy)",
      value: null,
    },
  ],
};

const staff = {
  name: "staff",
  cost: 100,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "staff",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Staff",
  campaign: "baseGame",
  isExhausted: false,
  isExhaustable: false,
  isConsumable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: ["shield"],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "Staff: Attack diagonally",
      value: null,
    },
  ],
};

const wandOfMagic = {
  name: "wandOfMagic",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "wand-of-magic",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Wand of Magic",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.BARBARIAN, characterTypes.DWARF],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "Wand of Magic: Cast two different spells per turn",
      value: null,
    },
  ],
};

const wizardsStaff = {
  name: "wizardsStaff",
  cost: null,
  description: "",
  type: equipmentTypes.WEAPON,
  image: "wizards-staff",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Wizard's Staff",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [
    characterTypes.BARBARIAN,
    characterTypes.DWARF,
    characterTypes.ELF,
  ],
  pairedEquipmentRestrictions: ["shield"],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "replace",
      value: 2,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "Wizard's Staff: Attack diagonally",
      value: null,
    },
  ],
};

export const weapons = {
  battleAxe,
  broadsword,
  crossbow,
  dagger,
  fortunesLongsword,
  handAxe,
  longsword,
  magicalThrowingDagger,
  orcsBane,
  phantomBlade,
  rodOfTelekinesis,
  shortsword,
  spiritBlade,
  staff,
  wandOfMagic,
  wizardsStaff,
};
