import React from "react";
import styled from "styled-components";
import {
  Paper,
  Modal,
  TextField,
  ToggleButton,
  CircularProgress,
} from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";

import { characterClasses } from "@entities/characters/model/characterClasses";
import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import { CharacterIcon } from "@entities/characters/ui/CharacterIcon";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";

// TODO: move selectors and slice
import { createHeroModal } from "@entities/modal/model/modalSelectors";
import { updateModal, clearModal } from "@entities/modal/model/modalSlice";

const HeroIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
`;
const HeroIconButton = styled(ToggleButton)`
  width: 50px;
  height: 50px;
`;

const CreateHeroModal = () => {
  const { createHero } = useHeroAPI();
  const dispatch = useDispatch();
  const modalState = useSelector(createHeroModal);

  const { isLoading, name, heroName, heroType } = modalState;
  const isOpen = name === "createHeroModal";

  async function handleCreateHero() {
    dispatch(updateModal({ isLoading: true }));
    await createHero(heroName, heroType);
    dispatch(clearModal());
  }

  const handleClose = () => {
    dispatch(clearModal());
  };

  const handleCharacterChange = (character) => {
    dispatch(updateModal({ heroType: character }));
  };

  const handleNameChange = (event) => {
    dispatch(updateModal({ heroName: event.target.value }));
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper>
          <ModalContainer>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={handleNameChange}
              value={heroName}
            />
            <HeroIcons>
              <HeroIconButton
                value={characterClasses.BARBARIAN.character}
                selected={heroType === characterClasses.BARBARIAN.character}
                onChange={() =>
                  handleCharacterChange(characterClasses.BARBARIAN.character)
                }
                color="secondary"
              >
                <CharacterIcon
                  character={characterClasses.BARBARIAN.character}
                  fontSize="large"
                />
              </HeroIconButton>
              <HeroIconButton
                value={characterClasses.DWARF.character}
                selected={heroType === characterClasses.DWARF.character}
                onChange={() =>
                  handleCharacterChange(characterClasses.DWARF.character)
                }
                color="secondary"
              >
                <CharacterIcon
                  character={characterClasses.DWARF.character}
                  fontSize="large"
                />
              </HeroIconButton>
              <HeroIconButton
                value={characterClasses.ELF.character}
                selected={heroType === characterClasses.ELF.character}
                onChange={() =>
                  handleCharacterChange(characterClasses.ELF.character)
                }
                color="secondary"
              >
                <CharacterIcon
                  character={characterClasses.ELF.character}
                  fontSize="large"
                />
              </HeroIconButton>
              <HeroIconButton
                value={characterClasses.WIZARD.character}
                selected={heroType === characterClasses.WIZARD.character}
                onChange={() =>
                  handleCharacterChange(characterClasses.WIZARD.character)
                }
                color="secondary"
              >
                <CharacterIcon
                  character={characterClasses.WIZARD.character}
                  fontSize="large"
                />
              </HeroIconButton>
            </HeroIcons>
            <ButtonsContainer>
              <ModalButton
                variant="contained"
                color="secondary"
                onClick={handleCreateHero}
                disabled={!heroName || !heroType}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "Create Hero"
                )}
              </ModalButton>
              <ModalButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </ModalButton>
            </ButtonsContainer>
          </ModalContainer>
        </Paper>
      </Modal>
    </>
  );
};

export default CreateHeroModal;
