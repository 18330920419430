import { createSelector } from "reselect";
import { selectedHeroCampaign } from "@entities/heroes/model/heroesSelectors";
import { getCampaignItems } from "../viewCampaignUtilities";

import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import filter from "lodash/filter";

import { potions } from "@entities/equipment/model/potions";
import { items } from "@entities/equipment/model/items";
import { armor } from "@entities/equipment/model/armor";
import { weapons } from "@entities/equipment/model/weapons";

export const getTreasureItems = (items) =>
  filter(items, (item) => item?.cost === null && item?.isArtifact !== true);

export const potionTreasureList = getTreasureItems(potions);
export const itemTreasureList = getTreasureItems(items);
export const armorTreasureList = getTreasureItems(armor);
export const weaponTreasureList = getTreasureItems(weapons);
export const itemsAndPotionsTreasureList = sortBy(
  concat(itemTreasureList, potionTreasureList),
  ["name"]
);

export const potionTreasureListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(potionTreasureList, heroCampaign)
);
export const itemTreasureListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemTreasureList, heroCampaign)
);
export const armorTreasureListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(armorTreasureList, heroCampaign)
);
export const weaponTreasureListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(weaponTreasureList, heroCampaign)
);

export const itemsAndPotionsTreasureListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemsAndPotionsTreasureList, heroCampaign)
);

export const treasureListForHeroCampaign = createSelector(
  weaponTreasureListForHeroCampaign,
  armorTreasureListForHeroCampaign,
  itemTreasureListForHeroCampaign,
  potionTreasureListForHeroCampaign,
  (weapons, armor, items, potion) => {
    return sortBy([...weapons, ...armor, ...items, ...potion], ["name"]);
  }
);
