/* src/App.js */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Paper, Modal, CircularProgress } from "@mui/material/";
import { Typography } from "@material-ui/core";

import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";
import {
  ButtonsContainer,
  ModalButton,
} from "@shared/ui/Modals/ModalComponents.styled";
import ModalContainer from "@shared/ui/Modals/ModalContainer";
import CardButton from "@shared/ui/Cards/CardButton";
import useEquipmentAPI from "@entities/equipment/hooks/useEquipmentAPI";

import {
  selectedHeroWeapons,
  selectedHeroArmor,
  selectedHeroSpells,
} from "@entities/heroes/model/heroesSelectors";
import { clearModal, updateModal } from "@entities/modal/model/modalSlice";
import { equipmentModal } from "@entities/modal/model/modalSelectors";
import { equipItem, unequipItem } from "@entities/modal/model/modalSlice";

const InvntoryPaper = styled(Paper)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

export const EquipmentModal = () => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(clearModal());
  const modalState = useSelector(equipmentModal);
  const weapons = useSelector(selectedHeroWeapons);
  const armor = useSelector(selectedHeroArmor);
  const spells = useSelector(selectedHeroSpells);
  const { updateHeroEquipment, deleteHeroEquipment } = useEquipmentAPI();
  const {
    name,
    equipmentType,
    equippedItem,
    itemToEquip,
    equipmentSlot,
    isLoading,
  } = modalState;
  const modalItems = {
    [equipmentTypes.WEAPON]: weapons,
    [equipmentTypes.ARMOR]: armor,
    [equipmentTypes.SPELL]: spells,
  };

  const isOpen = name === "equipItems";
  const isEquipmentChanged = equippedItem?.id !== itemToEquip?.id;
  if (!equipmentType) return null;

  const handleSave = async () => {
    const newEquipedItemInput = {
      id: itemToEquip?.id,
      equipmentSlot,
    };

    dispatch(updateModal({ isLoading: true }));
    // equip new item
    await updateHeroEquipment({
      input: newEquipedItemInput,
    });

    // unequip previous item if it is different from new weapon
    if (itemToEquip?.id !== equippedItem?.id) {
      const itemToRemoveInput = {
        id: equippedItem?.id,
        equipmentSlot: null,
      };
      await updateHeroEquipment({
        input: itemToRemoveInput,
      });
    }
    dispatch(clearModal());
  };

  const handleUseRefreshClick = async () => {
    if (itemToEquip?.isExhaustable) {
      dispatch(updateModal({ isLoading: true }));
      await updateHeroEquipment({
        input: { id: itemToEquip?.id, isExhausted: !itemToEquip?.isExhausted },
      });
    } else if (itemToEquip?.isConsumable) {
      dispatch(updateModal({ isLoading: true }));
      await deleteHeroEquipment(itemToEquip?.id);
    }
    dispatch(clearModal());
  };

  const itemButtons = modalItems[equipmentType].reduce(
    (equipmentButtons, i, index) => {
      const isSelected = i.id === itemToEquip?.id;
      const isCardShown = i.id !== null;
      const isItemlreadyEquippedElsewhere =
        equippedItem?.id !== i.id && i.equipmentSlot !== null;

      // Don't inlclude already equipped items as options.
      if (isItemlreadyEquippedElsewhere) return equipmentButtons;

      // Unequip if item is already equipped and equip if item was not already equipped.
      const equipmentClick = () => {
        if (itemToEquip?.id === i.id && i.equipmentSlot !== null) {
          dispatch(unequipItem());
        } else {
          dispatch(equipItem(i));
        }
      };

      const handleDeleteItemClick = () => {
        deleteHeroEquipment(i.id);
        dispatch(unequipItem());
      };

      equipmentButtons.push(
        <CardButton
          key={`${i.id}-${index}`}
          name={i.name}
          type={equipmentType}
          showCard={isCardShown}
          isSelectable={true}
          selected={isSelected}
          imageURL={`/${equipmentType.toLowerCase()}/${i.image}.jpeg`}
          onClick={equipmentClick}
          onDeleteClick={handleDeleteItemClick}
          zoom={true}
        />
      );

      return equipmentButtons;
    },
    []
  );
  const exhaustableButtonLabel = itemToEquip?.isExhausted
    ? "Refresh"
    : "Exhaust";
  const consumableButtonLabel = itemToEquip?.isConsumable ? "Use" : "";
  const buttonLabel = itemToEquip?.isConsumable
    ? consumableButtonLabel
    : itemToEquip?.isExhaustable
    ? exhaustableButtonLabel
    : "";
  const isItemAvailable = itemButtons.length > 0;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={0}>
        <ModalContainer>
          <ItemsContainer>
            {isItemAvailable ? (
              itemButtons
            ) : (
              <InvntoryPaper>
                <Typography gutterBottom variant="h6" component="div">
                  No {equipmentType.toLowerCase()} available
                </Typography>
              </InvntoryPaper>
            )}
          </ItemsContainer>
          {itemToEquip?.isConsumable ? (
            <Typography>
              This item is consumable and will be removed when used.
            </Typography>
          ) : null}
          <ButtonsContainer>
            {itemToEquip?.isExhaustable || itemToEquip?.isConsumable ? (
              <ModalButton
                variant="contained"
                color="secondary"
                onClick={handleUseRefreshClick}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  buttonLabel
                )}
              </ModalButton>
            ) : null}
            <ModalButton
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={!isItemAvailable || !isEquipmentChanged || isLoading}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "Save"}
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </Paper>
    </Modal>
  );
};

export default EquipmentModal;
