import styled from "styled-components";
import { Button } from "@mui/material/";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 90px; */
  margin-top: 1rem;
  justify-content: space-around;
`;

export const ModalButton = styled(Button)`
  &&& {
    margin-bottom: 0.5rem;
  }
`;
