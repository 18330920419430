export const createHeroEquipment = /* GraphQL */ `
  mutation CreateHeroEquipment(
    $input: CreateHeroEquipmentInput!
    $condition: ModelHeroEquipmentConditionInput
  ) {
    createHeroEquipment(input: $input, condition: $condition) {
      id
      heroId
      equipmentId
      name
      displayName
      description
      type
      subType
      image
      backImage
      modifiers {
        attribute
        modification
        value
      }
      cost
      equipmentSlot
      isArtifact
      isExhausted
      isExhaustable
      isConsumable
      characterRestrictions
      pairedEquipmentRestrictions
      hero {
        equipment {
          items {
            id
            heroId
            equipmentId
            name
            displayName
            description
            type
            subType
            image
            backImage
            modifiers {
              attribute
              modification
              value
            }
            cost
            equipmentSlot
            isArtifact
            isExhausted
            isExhaustable
            isConsumable
            characterRestrictions
            pairedEquipmentRestrictions
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateHeroEquipment = /* GraphQL */ `
  mutation UpdateHeroEquipment(
    $input: UpdateHeroEquipmentInput!
    $condition: ModelHeroEquipmentConditionInput
  ) {
    updateHeroEquipment(input: $input, condition: $condition) {
      id
      heroId
      equipmentId
      name
      displayName
      description
      type
      subType
      image
      backImage
      modifiers {
        attribute
        modification
        value
      }
      cost
      equipmentSlot
      isArtifact
      isExhausted
      isExhaustable
      isConsumable
      characterRestrictions
      pairedEquipmentRestrictions
      hero {
        equipment {
          items {
            id
            heroId
            equipmentId
            name
            displayName
            description
            type
            subType
            image
            backImage
            modifiers {
              attribute
              modification
              value
            }
            cost
            equipmentSlot
            isArtifact
            isExhausted
            isExhaustable
            isConsumable
            characterRestrictions
            pairedEquipmentRestrictions
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteHeroEquipment = /* GraphQL */ `
  mutation DeleteHeroEquipment(
    $input: DeleteHeroEquipmentInput!
    $condition: ModelHeroEquipmentConditionInput
  ) {
    deleteHeroEquipment(input: $input, condition: $condition) {
      id
      heroId
      hero {
        equipment {
          items {
            id
            heroId
            equipmentId
            name
            displayName
            description
            type
            subType
            image
            backImage
            modifiers {
              attribute
              modification
              value
            }
            cost
            equipmentSlot
            isArtifact
            isExhausted
            isExhaustable
            isConsumable
            characterRestrictions
            pairedEquipmentRestrictions
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
