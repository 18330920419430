const campaignID = "frozenHorror";

const intro = {
  number: 0,
  campaign: campaignID,
  title: "Intro",
  description:
    "Welcome, Barbarian, to the land of your youth! It must seem like only yesterday that your tribal elders gave you leave to explore the warm and exotic lands of the south. You return now as a renowned warrior: a Champion of the Empire. Glad though your homecoming is, bitter tidings greet you as the tribal elders tell you why you have been summoned. Your people need aid in this, the time of their most dire need. The Legends tell that long ago, a creature known as the Frozen Horror came to Ice Mountain, the highest peak in the Xyberian Range. This fiend was a powerful ally of Morcar, and set about remaking the world into one of perpetual, frozen winter. It was by the actions of Kelvinos, a mighty Champion, that the Horror was slain. Some say that Kelvinos carried the Amulet of the North, an artefact of tremendous power, and that it was lost in the depths of Ice Mountain. None can say for sure, for Kelvinos never returned from Ice Mountain. Now though, the Frozen Horror has returned, arisen from it's icy tomb where it has dwelt these last centuries. At Morcar's call, it has returned to its seat of power high on Ice Mountain. It had regained the Sceptre of Glacial Majesty, a Chaos artefact of great power. As we speak, the Horror and it's minions are planning to cover the Northlands and the Empire in a shroud of deadly ice. Your people once more need a Champion. Your friends and followers must be trained to survive the frozen north. While they do so, you have been set three perilous tasks to further our cause. Then, you will lead your companions to Ice Mountain. There you must locate and destroy the Sceptre of Glacial Majesty before its master can reawaken its full power. Then you must face the Frozen Horror and destroy it forever. You must call upon all your courage and skill. The greatest perils you have yet faced awaits within Ice Mountain. You must not waver nor fail: the lives of both the Barbarian Tribes and of the Empire, rest on your shoulders.",
};

const xanonPass = {
  number: 1,
  campaign: campaignID,
  title: "Solo Quest I - Xanon Pass",
  description:
    "“Good fortune to you Barbarian, on this, the first of your three tasks. The Servants of the Frozen Horror have overrun the fortress that guards the Xanon Pass, the only route through the Xyberian range, which you must cross to reach Ice Mountain. You must clear the fortress of the Horror's minions and find your way through to the pass itself.”",
};

const trialByIce = {
  number: 2,
  campaign: campaignID,
  title: "Solo Quest II - Trial by Ice",
  description:
    "At the head of the Xanon Pass lies an underground outpost on the slopes of the Xyberian Range. This outpost is a bass of operations for the minions of the Frozen Horror. Several local villages are threatened by these fiends. You must enter the outpost and slay the Frozen Horror's Lieutenant, the Chaos Warrior named Kraag.",
};

const rescue = {
  number: 3,
  campaign: campaignID,
  title: "Solo Quest III - The Rescue",
  description:
    "The minions of the Frozen Horror have captured Gothar, your tribe's Great Elder. He has been taken to a Chaos Stronghold close to Ice Mountain. Gothar has great wisdom, but he is old and frail. He cannot long survive the cruel hands of the creatures of Chaos. Your final task is to enter the stronghold and repair Gothar safely to the tribe.",
};

const glacialGate = {
  number: 4,
  campaign: campaignID,
  title: "The Glacial Gate",
  description:
    "You have done well, Barbarian! The Tribal elders have appointed you their champion to oppose the Frozen Horror and its minions. Now you and your companions are ready to assault Ice Mountain. First you must pass through the outer bastion known as the Glacial Gate, which guards the deeper reaches of the mountain's fastness.",
};

const deadlyDepths = {
  number: 5,
  campaign: campaignID,
  title: "The Deadly Depths",
  description:
    "Throughout Ice Mountain, your presence causes alarms to be sounded, awakening the servants of the Frozen Horror from their icy slumber. Your journey through the stronghold will become harder after this. This region of the fortress is guarded by Vilor, the Chaos Sorcerer, who has been ordered to halt your progress.",
};

const frostedPath = {
  number: 6,
  campaign: campaignID,
  title: "The Frosted Path",
  description:
    "These hallways have lain undisturbed for centuries. Now they are inhabited once again by the foul creatures of Chaos. The Frozen Horror's minions are gathering to halt your progress. You must fight your way through towards the chambers of the Frozen Horror.",
};

const hallsOfKelvinos = {
  number: 7,
  campaign: campaignID,
  title: "The Halls of Kelvinos",
  description:
    "Many centuries ago, Kelvinos was a famed and mighty Hero from the barbarian tribes. It was he that defeated the Frozen Horror and drove his minions from Ice Mountain. Sadly, Kelvinos never returned from Ice Mountain. Some say he walks again, as an Undead pawn of the Chaos powers.",
};

const searchForTheSceptre = {
  number: 8,
  campaign: campaignID,
  title: "Search for the Sceptre",
  description:
    "You have come far my friends, but there is still far to go. Within these chamber lies the Sceptre of Glacial Majesty, the source of the Frozen Horror's power. You must find and destroy it. If the Sceptre should fall into the clutches of the Horror once more, all would be lost. Be careful, for the chambers of the Frozen Horror lie close to here.",
};

const heartOfIce = {
  number: 9,
  campaign: campaignID,
  title: "The Heart of Ice",
  description:
    "Now that you have destroyed the Sceptre of Glacial Majesty, the Frozen Horror awaits you. Beneath his chambers lies the Frozen Catacombs, wherein lies the means to break the protective wards that keep you from him, the Crystal Key. Once you have found this key, you may face the Horror in it's very seat of power. Take care, for the Horror is a most formidable foe.",
};

const epilogue = {
  number: 10,
  campaign: campaignID,
  title: "Epilogue",
  description:
    "Congratulations! The Frozen Horror has been defeated. The hordes of the Barbarian Tribes have been able to clear the lower passes of his remaining minions. Those few that remain are to be hunted down. Morcar's conquest of the North have been quashed, and the Empire is safe from an ice-shrouded doom. The Frozen Horror returned from death once before. It is possible it may do so again. For now though the danger is passed. The barbarian Tribes may need to find new champions to defend them centuries from now. May they find champions as worthy as you. Tales are already being told in the barbarian camps of your cunning, bravery and skill. The tribes will sing your praises forever. In appreciation of your deeds, the Tribal Elders have granted each of you a purse of one thousand Gold Crowns. Morcar's plans elsewhere continue unabated. We are sorely pressed on many fronts. Soon your valour will be needed again, in many dangerous tasks that yet await you. But tonight you are to be honoured guests at a great feast. Tales of valour will be told amongst much rejoicing. Rest well this night my friends.",
};

const quests = [
  intro,
  xanonPass,
  trialByIce,
  rescue,
  glacialGate,
  deadlyDepths,
  frostedPath,
  hallsOfKelvinos,
  searchForTheSceptre,
  heartOfIce,
  epilogue,
];

export const frozenHorror = {
  id: campaignID,
  display: "The Frozen Horror",
  quests,
};
