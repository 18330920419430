import React from "react";
import styled from "styled-components";
import { Typography, Paper, Card } from "@mui/material/";

import { AddHeroButton } from "@features/heroes/add-hero/AddHeroButton";
import { SignoutButton } from "@features/signout";
import { HeroListItems } from "@widgets/hero-list/HeroListItems";

const HeroCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const MainContainer = styled(Paper)`
  max-width: 700px;
  width: 100%;
  border: 1px Solid #c7c7c7;
`;

const TitleContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div``;

export const HeroList = ({ heroes }) => {
  return (
    <>
      <MainContainer elevation={0}>
        <TitleContainer>
          <Title>
            <Typography variant="h6" component="div">
              Characters
            </Typography>
          </Title>
          <SignoutButton />
        </TitleContainer>
        <HeroCardContainer>
          <AddHeroButton />
        </HeroCardContainer>
        <HeroListItems heroes={heroes} />
      </MainContainer>
    </>
  );
};

export default HeroList;
