import { SvgIcon } from "@material-ui/core";

export const WeaponIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M62.936 16.73L19.72 59.95c1.622 12.54 7.583 26.894 19.39 41.904l65.974-65.973C90.058 24.2 75.52 18.26 62.937 16.73zM96.1 71.295L74.895 92.5c6.34 11.07 15.39 23.783 26.533 36.965l25.197-36.94c-10.865-8.725-21.27-15.93-30.525-21.23zM258.627 88.26l19.406 19.408L112.15 273.55l-19.408-19.405 34.88 101.255 54.08-12.298-51.913-51.91 165.884-165.883 51.908 51.907 12.3-54.08L258.628 88.26zm-117.682 16.435L114.25 143.83c2.63 2.796 5.335 5.595 8.125 8.385 1.305 1.305 2.612 2.592 3.92 3.863l38.05-28.486c-2.757-2.94-5.6-5.883-8.532-8.817-4.95-4.95-9.925-9.645-14.868-14.08zm35.877 36.903l-36.564 27.37c9.672 8.487 19.167 15.894 28.04 22.005l26.266-26.266c-5.07-7.363-11.01-15.144-17.742-23.11zm101.34 27.652l-22.967 22.967c54.55 55.23 93.45 99.893 117.893 132.922 13.023 17.596 21.977 31.83 27.18 43.22 2.6 5.695 4.322 10.65 4.923 15.554.603 4.903-.024 10.743-4.266 15.025-4.243 4.28-10.11 4.957-15.014 4.373-4.905-.585-9.854-2.296-15.54-4.887-11.375-5.182-25.592-14.118-43.183-27.135-33.02-24.434-77.71-63.365-133.044-118.02l-21.457 21.457c78.36 65.017 223.158 187.35 324.5 219.406-36.243-99.91-155.985-246.05-219.024-324.883zm-36.183 36.184l-34.623 34.62c54.882 54.197 99.09 92.638 130.948 116.214 16.98 12.565 30.55 20.932 39.812 25.152 3.486 1.588 6.076 2.39 8.057 2.863-.476-2-1.294-4.627-2.906-8.158-4.244-9.292-12.63-22.88-25.202-39.87-23.586-31.868-61.997-76.044-116.087-130.82z" />
    </SvgIcon>
  );
};

export default WeaponIcon;
