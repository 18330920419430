import { Person } from "@mui/icons-material";

import { characterTypes } from "@entities/characters/model/characterTypes";
import BarbarianIcon from "./BarbarianIcon";
import DwarfIcon from "./DwarfIcon";
import ElfIcon from "./ElfIcon";
import WizardIcon from "./WizardIcon";

export const CharacterIcon = ({ character, ...props }) => {
  if (character === characterTypes.BARBARIAN)
    return <BarbarianIcon {...props} />;
  if (character === characterTypes.DWARF) return <DwarfIcon {...props} />;
  if (character === characterTypes.ELF) return <ElfIcon {...props} />;
  if (character === characterTypes.WIZARD) return <WizardIcon {...props} />;
  return <Person {...props} />;
};

export default CharacterIcon;
