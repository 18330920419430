import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material/";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

import { updateModal } from "@entities/modal/model/modalSlice";

export const DeleteHeroButton = ({ id }) => {
  const dispatch = useDispatch();

  const handleDeleteClick = () =>
    dispatch(
      updateModal({
        name: "deleteHeroModal",
        heroId: id,
      })
    );

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={handleDeleteClick}
      >
        <DeleteIcon />
      </Button>
    </>
  );
};

DeleteHeroButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeleteHeroButton;
