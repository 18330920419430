import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import HeroquestLogo from "@shared/ui/Icons/HeroquestLogo";
import {
  isLoading as isLoadingHeroes,
  heroesList,
} from "@entities/heroes/model/heroesSelectors";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";
import CreateHeroModal from "@features/heroes/add-hero/CreateHeroModal";
import DeleteHeroModal from "@features/heroes/delete-hero/DeleteHeroModal";
import HeroList from "@widgets/hero-list/HeroList";

export const MainMenu = () => {
  const { fetchHeroes } = useHeroAPI();
  const heroes = useSelector(heroesList);

  useEffect(() => {
    fetchHeroes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingHerosData = useSelector(isLoadingHeroes);
  if (isLoadingHerosData) return <HeroquestLogo />;
  return (
    <>
      <CreateHeroModal />
      <DeleteHeroModal />
      <HeroquestLogo />
      <HeroList heroes={heroes} />
    </>
  );
};

export default MainMenu;
