import * as React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material/";
import { Button } from "@mui/material/";

const HeroAttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  width: 5rem;
`;

const AttributeButtonStyled = styled(Button)`
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 60px;
  &&& {
    min-width: 40px;
    margin: 4px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem;
`;

const OriginalValueContainer = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 60%;
`;

const HeroAttribute = ({
  baseValue,
  value,
  icon,
  isDisabled = false,
  onClick,
}) => {
  return (
    <HeroAttributeContainer>
      <IconContainer>{icon}</IconContainer>
      <AttributeButtonStyled
        variant="contained"
        color="secondary"
        onClick={onClick}
        disabled={isDisabled}
      >
        <Typography variant="h4">{value}</Typography>
        <OriginalValueContainer>{baseValue}</OriginalValueContainer>
      </AttributeButtonStyled>
    </HeroAttributeContainer>
  );
};

export default HeroAttribute;
