const MONSTER = "MONSTER";

const abomination = {
  name: "abomination",
  type: MONSTER,
  displayName: "Abomination",
  campaign: "baseGame",
  image: "abomination",
  backImage: "monster-back",
};

const dreadWarrior = {
  name: "dreadWarrior",
  type: MONSTER,
  displayName: "Dread Warrior",
  campaign: "baseGame",
  image: "dread-warrior",
  backImage: "monster-back",
};

const gargoyle = {
  name: "gargoyle",
  type: MONSTER,
  displayName: "Gargoyle",
  campaign: "baseGame",
  image: "gargoyle",
  backImage: "monster-back",
};

const goblin = {
  name: "goblin",
  type: MONSTER,
  displayName: "Goblin",
  campaign: "baseGame",
  image: "goblin",
  backImage: "monster-back",
};

const mummy = {
  name: "mummy",
  type: MONSTER,
  displayName: "Mummy",
  campaign: "baseGame",
  image: "mummy",
  backImage: "monster-back",
};

const orc = {
  name: "orc",
  type: MONSTER,
  displayName: "Orc",
  campaign: "baseGame",
  image: "orc",
  backImage: "monster-back",
};

const skeleton = {
  name: "skeleton",
  type: MONSTER,
  displayName: "Skeleton",
  campaign: "baseGame",
  image: "skeleton",
  backImage: "monster-back",
};

const zombie = {
  name: "zombie",
  type: MONSTER,
  displayName: "Zombie",
  campaign: "baseGame",
  image: "zombie",
  backImage: "monster-back",
};

export const monsters = {
  abomination,
  dreadWarrior,
  gargoyle,
  goblin,
  mummy,
  orc,
  skeleton,
  zombie,
};

export const monsterList = Object.values(monsters);
