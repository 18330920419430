import { API, graphqlOperation } from "aws-amplify";
import { useDispatch } from "react-redux";

import {
  updateHeroEquipment as updateHeroEquipmentMutation,
  createHeroEquipment as createHeroEquipmentMutation,
  deleteHeroEquipment as deleteHeroEquipmentMutation,
} from "../model/heroEquipmentMutations";
import { updatedHeroEquipment } from "@entities/heroes/model/heroesSlice";
import { getSpellsToAdd } from "@entities/heroes/heroUtils";

function useEquipmentAPI() {
  const dispatch = useDispatch();

  const updateHeroEquipment = async ({ input }) => {
    try {
      if (!input?.id) return;
      const response = await API.graphql(
        graphqlOperation(updateHeroEquipmentMutation, {
          input,
        })
      );
      dispatch(
        updatedHeroEquipment(
          response?.data?.updateHeroEquipment?.hero?.equipment
        )
      );
    } catch (err) {
      console.log("error updating hero equipment:", err);
    }
  };

  const createHeroEquipment = async ({ heroId, ...equipmentToAdd }) => {
    try {
      if (!heroId) throw Error("no heroId provided");
      const response = await API.graphql(
        graphqlOperation(createHeroEquipmentMutation, {
          input: { heroId, ...equipmentToAdd },
        })
      );

      dispatch(
        updatedHeroEquipment(
          response?.data?.createHeroEquipment?.hero?.equipment
        )
      );
    } catch (err) {
      console.log("error creating hero equipment:", err);
    }
  };

  const deleteHeroEquipment = async (heroEquipmentId) => {
    try {
      if (!heroEquipmentId) throw Error("no heroId provided");
      const response = await API.graphql(
        graphqlOperation(deleteHeroEquipmentMutation, {
          input: { id: heroEquipmentId },
        })
      );
      dispatch(
        updatedHeroEquipment(
          response?.data?.deleteHeroEquipment?.hero?.equipment
        )
      );
    } catch (err) {
      console.log("error deleting hero equipment:", err);
    }
  };

  const equipSpells = async ({
    selectedSpellTypes,
    equippedSpells,
    heroId,
  }) => {
    try {
      for (
        let deleteIndex = 0;
        deleteIndex < equippedSpells.length;
        deleteIndex++
      ) {
        const spellId = equippedSpells[deleteIndex]?.id;
        await API.graphql(
          graphqlOperation(deleteHeroEquipmentMutation, {
            input: { id: spellId },
          })
        );
      }
    } catch (err) {
      console.log("error deleting spells", err);
    }

    const spellListToAdd = getSpellsToAdd(selectedSpellTypes);

    try {
      for (let addIndex = 0; addIndex < spellListToAdd.length; addIndex++) {
        createHeroEquipment({
          heroId,
          ...spellListToAdd[addIndex],
          equipmentSlot: addIndex,
        });
      }
    } catch (err) {
      console.log("error adding spells:", err);
    }
  };

  return {
    createHeroEquipment,
    updateHeroEquipment,
    deleteHeroEquipment,
    equipSpells,
  };
}

export default useEquipmentAPI;
