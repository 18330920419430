import React from "react";
import MainMenu from "../pages/main-menu/MainMenu";
import { HeroCard } from "./hero-card";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import ErrorPage from "./error/ErrorPage";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/common/brick.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color;
  height: 100%;
`;

function App({ signOut, user }) {
  return (
    <AppContainer className="App">
      <Routes>
        <Route
          path="/heroes"
          element={<MainMenu />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/hero/:id/*"
          element={<HeroCard />}
          errorElement={<ErrorPage />}
        />
      </Routes>
    </AppContainer>
  );
}

export const AppRoutes = withAuthenticator(App);
