import {
  characterTypes,
  characterAttributeTypes,
} from "@entities/characters/model/characterTypes";

import { equipmentTypes } from "./equipmentTypes";

const elixirOfLife = {
  name: "elixirOfLife",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "elixir-of-life",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Elixir of Life",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification: "restore",
      value: 0,
    },
    {
      attribute: characterAttributeTypes.mind,
      modification: "restore",
      value: 0,
    },
  ],
};

const heroicBrew = {
  name: "heroicBrew",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "heroic-brew",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Heroic Brew",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "make 2 attacks instead of one",
      value: 0,
    },
  ],
};

const holyWater = {
  name: "holyWater",
  cost: 400,
  description:
    "You may use the holy water instead of attacking. It kills any undead creature (skeleton, zombie, or mummy). The card is then discarded after use.",
  type: equipmentTypes.POTION,
  image: "holy-water",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Holy Water",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [],
};

const potionBattleRage = {
  name: "potionBattleRage",
  cost: 400,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-battle-rage",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Battle Rage",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [{ modification: "attacks per turn", value: 2 }],
};

const potionBattle = {
  name: "potionBattle",
  cost: 200,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-battle",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Battle",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [{ modification: "reroll attack dice", value: 1 }],
};

const potionDefense = {
  name: "potionDefense",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-defense",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Defense",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "roll 2 extra die next defense",
      value: 2,
    },
  ],
};

const potionDexterity = {
  name: "potionDexterity",
  cost: 100,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-dexterity",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Dexterity",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    { modification: "add 5 movement squares" },
    { modification: "guarantee pit jump" },
    { modification: "one per turn" },
  ],
};

const potionFrostSkin = {
  name: "potionFrostSkin",
  cost: 300,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-frost-skin",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Frost Skin",
  campaign: "frozenHorror",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [
    characterTypes.wizard,
    characterTypes.ELF,
    characterTypes.dwarf,
  ],
  pairedEquipmentRestrictions: [],
  modifiers: [{ modification: "roll 2 defense dice" }],
};

const potionHealing = {
  name: "potionHealing",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-healing",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Healing",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification: "roll 1 red die",
      value: 0,
    },
  ],
};

const potionIcyStrength = {
  name: "potionIcyStrength",
  cost: 200,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-icy-strength",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Icy Strength",
  campaign: "frozenHorror",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [
    characterTypes.wizard,
    characterTypes.ELF,
    characterTypes.dwarf,
  ],
  pairedEquipmentRestrictions: [],
  modifiers: [{ modification: "next attack causes 2x damage" }],
};

const potionRejuvenation = {
  name: "potionRejuvenation",
  cost: 500,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-rejuvenation",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Rejuvenation",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification: "roll 1 red die to gain body points",
      value: 6,
    },
  ],
};

const potionRestoration = {
  name: "potionRestoration",
  cost: 500,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-restoration",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Restoration",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    { attribute: characterAttributeTypes.body, modification: "add", value: 1 },
    { attribute: characterAttributeTypes.mind, modification: "add", value: 1 },
  ],
};

const potionSpeed = {
  name: "potionSpeed",
  cost: 200,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-speed",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Speed",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.movement,
      modification: "roll 2x red dice",
      value: 1,
    },
  ],
};

const potionStrength = {
  name: "potionStrength",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-strength",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Strength",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "roll 2 die on next attack ",
      value: 2,
    },
  ],
};

const potionWarmth = {
  name: "potionWarmth",
  cost: null,
  description: "",
  type: equipmentTypes.POTION,
  image: "potion-of-warmth",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Potion of Warmth",
  campaign: "frozenHorror",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification:
        "add if taken immediately after damage from Ice Storm, Chill spells, ice vaults, or icy rivers",
      value: 2,
    },
    {
      attribute: characterAttributeTypes.body,
      modification: "add if taken any other time",
      value: 1,
    },
  ],
};

const venomAntidote = {
  name: "venomAntidote",
  cost: 300,
  description: "",
  type: equipmentTypes.POTION,
  image: "venom-antidote",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Venom Antidote",
  campaign: "frozenHorror",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    { attribute: characterAttributeTypes.body, modification: "add", value: 2 },
    { modification: "heals damage caused by poison needs and darts only" },
  ],
};

export const potions = {
  elixirOfLife,
  heroicBrew,
  holyWater,
  potionBattleRage,
  potionBattle,
  potionDefense,
  potionDexterity,
  potionFrostSkin,
  potionHealing,
  potionIcyStrength,
  potionRejuvenation,
  potionRestoration,
  potionSpeed,
  potionStrength,
  potionWarmth,
  venomAntidote,
};
