import { createSelector } from "reselect";
import { selectedHeroCampaign } from "@entities/heroes/model/heroesSelectors";
import { getCampaignItems } from "../viewCampaignUtilities";

import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import filter from "lodash/filter";

import { potions } from "@entities/equipment/model/potions";
import { items } from "@entities/equipment/model/items";
import { armor } from "@entities/equipment/model/armor";
import { weapons } from "@entities/equipment/model/weapons";

export const getStoreItems = (items) =>
  filter(items, (item) => item?.cost !== null && item?.cost > 0);

export const potionStoreList = getStoreItems(potions);
export const itemStoreList = getStoreItems(items);
export const armorStoreList = getStoreItems(armor);
export const weaponStoreList = getStoreItems(weapons);
export const itemsAndPotionsStoreList = sortBy(
  concat(itemStoreList, potionStoreList),
  ["name"]
);

export const potionStoreListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(potionStoreList, heroCampaign)
);
export const itemStoreListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemStoreList, heroCampaign)
);
export const armorStoreListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(armorStoreList, heroCampaign)
);
export const weaponStoreListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(weaponStoreList, heroCampaign)
);

export const itemsAndPotionsStoreListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemsAndPotionsStoreList, heroCampaign)
);
