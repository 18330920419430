import {
  characterTypes,
  characterAttributeTypes,
} from "@entities/characters/model/characterTypes";

import { equipmentTypes } from "@entities/equipment/model/equipmentTypes";

const amuletOfTheNorth = {
  name: "amuletOfTheNorth",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "amulet-of-the-north",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Amulet of the North",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [
    characterTypes.WIZARD,
    characterTypes.ELF,
    characterTypes.DWARF,
  ],
  pairedEquipmentRestrictions: [],
  modifiers: [
    { attribute: characterAttributeTypes.body, modification: "add", value: 2 },
    { attribute: characterAttributeTypes.mind, modification: "add", value: 1 },
  ],
};

const borinsArmor = {
  name: "borinsArmor",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "borins-armor",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Borin's Armor",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 2,
    },
  ],
};

const bracers = {
  name: "bracers",
  cost: 550,
  description:
    "These hardened leather bracers give you 1 extra Defend die, May be combined with the helmet and/or shield.",
  type: equipmentTypes.ARMOR,
  image: "bracers",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Bracers",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 1,
    },
  ],
};

const chainMail = {
  name: "chainMail",
  cost: 500,
  description:
    "This light metal armor gives you 1 extra Defend die. May be combined with the helmet and/or shield. May not be worn by wizard.",
  type: equipmentTypes.ARMOR,
  image: "chain-mail",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Chain Mail",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 1,
    },
  ],
};

const fireRing = {
  name: "fireRing",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "fire-ring",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Fire Ring",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification: "Fire Ring: protects from 2 dread spells",
      value: 0,
    },
  ],
};

const helmet = {
  name: "helmet",
  cost: 125,
  description:
    "This protective headpiece gives you 1 extra Defend die. May not be worn by the wizard.",
  type: equipmentTypes.ARMOR,
  image: "helmet",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Helmet",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 1,
    },
  ],
};

const plateMail = {
  name: "plateMail",
  cost: 850,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "plate-mail",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Plate Mail",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [characterTypes.WIZARD],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 2,
    },
    {
      attribute: characterAttributeTypes.movement,
      modification: "replace",
      value: 1,
    },
  ],
};

const rabbitBoots = {
  name: "rabbitBoots",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "rabbit-boots",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Rabbit Boots",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification:
        "jump one trap per turn by not rolling black shield on 1 combat die",
      value: 0,
    },
  ],
};

const ringOfFortitude = {
  name: "ringOfFortitude",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "ring-of-fortitude",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Ring of Fortitude",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.body,
      modification: "add",
      value: 1,
    },
  ],
};

const ringOfReturn = {
  name: "ringOfReturn",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "ring-of-return",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Ring of Return",
  campaign: "baseGame",
  isConsumable: true,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.movement,
      modification: "returns all heroes within sight to quest starting point",
      value: 0,
    },
  ],
};

const ringOfWarmth = {
  name: "ringOfWarmth",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "ring-of-warmth",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Ring of Warmth",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.movement,
      modification:
        "immune to Chill spell effects and ice vaults and icy rivers",
      value: 0,
    },
  ],
};

const shield = {
  name: "shield",
  cost: 150,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "shield",
  backImage: "equipment-back",
  isArtifact: false,
  displayName: "Shield",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defense,
      modification: "add",
      value: 1,
    },
  ],
};

const snowshoesOfSpeed = {
  name: "snowshoesOfSpeed",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "snowshoes-of-speed",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Snowshoes of Speed",
  campaign: "frozenHorror",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.movement,
      modification: "add 2 squares of movement",
      value: 0,
    },
    {
      attribute: characterAttributeTypes.movement,
      modification: "negates effects of slippery ice",
      value: 0,
    },
    {
      attribute: characterAttributeTypes.movement,
      modification: "can only be worn in cold, icy regions",
      value: 0,
    },
  ],
};

const spellRing = {
  name: "spellRing",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "spell-ring",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Spell Ring",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.attack,
      modification: "cast 1 spell two times (not simultaneously)",
      value: 0,
    },
    {
      attribute: characterAttributeTypes.attack,
      modification: "must declare spells to store in ring at start of quest",
      value: 0,
    },
  ],
};

const talismanOfLore = {
  name: "talismanOfLore",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "talisman-of-lore",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Talisman of Lore",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.mind,
      modification: "add",
      value: 1,
    },
  ],
};

const wizardsClock = {
  name: "wizardsClock",
  cost: null,
  description: "",
  type: equipmentTypes.ARMOR,
  image: "wizards-cloak",
  backImage: "artifact-back",
  isArtifact: true,
  displayName: "Wizard's Cloak",
  campaign: "baseGame",
  isConsumable: false,
  isExhausted: false,
  isExhaustable: false,
  characterRestrictions: [
    characterTypes.ELF,
    characterTypes.BARBARIAN,
    characterTypes.DWARF,
  ],
  pairedEquipmentRestrictions: [],
  modifiers: [
    {
      attribute: characterAttributeTypes.defend,
      modification: "add",
      value: 1,
    },
  ],
};

export const armor = {
  amuletOfTheNorth,
  borinsArmor,
  bracers,
  chainMail,
  fireRing,
  helmet,
  plateMail,
  rabbitBoots,
  ringOfFortitude,
  ringOfReturn,
  ringOfWarmth,
  shield,
  snowshoesOfSpeed,
  spellRing,
  talismanOfLore,
  wizardsClock,
};
