import React from "react";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "@fontsource/do-hyeon";
import "./App.css";
import { ThemeProvider } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import { store } from "./store";
import awsExports from "../aws-exports";
import "./index.css";
import { AppRoutes } from "@pages";
import ErrorPage from "@pages/error/ErrorPage";
import ScrollToTop from "@shared/ui/ScrollToTop";
import { darkTheme } from "@shared/ui/theme";
import Splash from "@pages/splash/Splash";
import reportWebVitals from "./reportWebVitals";

Amplify.configure(awsExports);

const AppEntry = () => (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <StyledEngineProvider>
        <MuiThemeProvider theme={darkTheme}>
          <ThemeProvider theme={darkTheme}>
            <Routes>
              <Route
                path="/"
                element={<Splash />}
                errorElement={<ErrorPage />}
                exact
              />
              <Route
                path="/*"
                element={<AppRoutes />}
                errorElement={<ErrorPage />}
              />
            </Routes>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default AppEntry;
