import { createSlice } from "@reduxjs/toolkit";

export const heroesSlice = createSlice({
  name: "heroes",
  initialState: { data: [], edits: {}, selected: {}, isLoading: false },
  reducers: {
    fetchComplete: (state, action) => {
      const heroList = action.payload?.data?.listHeroes?.items;
      state.data = heroList;
      state.isLoading = false;
    },
    fetchStart: (state) => {
      state.isLoading = true;
    },
    fetchSelectedComplete: (state, action) => {
      const hero = action.payload?.data?.getHero;
      state.selected = hero;
      state.isLoading = false;
    },
    fetchSelectedStart: (state) => {
      state.isLoading = true;
    },
    clearSelected: (state) => {
      state.selected = {};
    },
    createdHero: (state, action) => {
      state?.data?.push(action.payload);
    },
    deletedHero: (state, action) => {
      const { id } = action.payload;
      state.data = state.data.filter((hero) => hero.id !== id);
    },
    updatedHeroEquipment: (state, action) => {
      const { payload } = action;
      state.selected.equipment = payload;
    },
    updatedHero: (state, action) => {
      const { payload } = action;
      state.selected = payload;
    },
    updatedHeroCompletedQuest: (state, action) => {
      const { payload } = action;
      state.selected.completedQuest = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchComplete,
  fetchStart,
  fetchSelectedComplete,
  fetchSelectedStart,
  clearSelected,
  createdHero,
  deletedHero,
  updatedHero,
  updatedHeroEquipment,
  updatedHeroCompletedQuest,
} = heroesSlice.actions;

export default heroesSlice.reducer;
