import { createSelector } from "reselect";

export const modal = (state) => state.modal;

export const equipmentModal = createSelector(modal, (m) => ({
  name: "",
  equipmentType: "",
  ...m,
}));

export const configureSpellsModal = createSelector(modal, (m) => ({
  name: "",
  selectedSpellTypes: [],
  ...m,
}));

export const acquireEquipmentModal = createSelector(modal, (m) => ({
  name: "",
  equipmentOwnedNames: [],
  itemToAcquire: null,
  ...m,
}));

export const useItemModal = createSelector(modal, (m) => ({
  name: "",
  itemToUse: null,
  ...m,
}));

export const viewMonsterModal = createSelector(modal, (m) => ({
  name: "",
  monster: null,
  ...m,
}));

export const createHeroModal = createSelector(modal, (m) => ({
  name: "",
  heroName: "",
  heroType: null,
  ...m,
}));

export const deleteHeroModal = createSelector(modal, (m) => ({
  name: "",
  heroId: "",
  ...m,
}));
