import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    clearModal: () => ({ ...initialState }),
    updateModal: (state, action) => ({ ...state, ...action.payload }),
    equipItem: (state, action) => ({
      ...state,
      itemToEquip: action.payload,
    }),
    unequipItem: (state) => ({
      ...state,
      itemToEquip: null,
    }),
    updateGold: (state, action) => ({ ...state, newGold: action.payload }),
    updateAttribute: (state, action) => ({
      ...state,
      modifier: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  clearModal,
  equipItem,
  unequipItem,
  updateAttribute,
  updateGold,
  updateModal,
} = modalSlice.actions;

export default modalSlice.reducer;
