import { configureStore } from "@reduxjs/toolkit";
import heroesReducer from "../entities/heroes/model/heroesSlice";
import modalReducer from "../entities/modal/model/modalSlice";

export const store = configureStore({
  reducer: {
    heroes: heroesReducer,
    modal: modalReducer,
  },
});
