/* eslint-disable */

export const getHero = /* GraphQL */ `
  query GetHero($id: ID!) {
    getHero(id: $id) {
      id
      owner
      name
      displayName
      character
      attack
      defense
      body
      mind
      gold
      campaign
      completedQuest {
        items {
          id
          heroId
          number
          campaign
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          heroId
          equipmentId
          name
          displayName
          description
          type
          subType
          image
          backImage
          cost
          equipmentSlot
          isArtifact
          isExhausted
          isExhaustable
          isConsumable
          characterRestrictions
          pairedEquipmentRestrictions
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getHeroEquipment = /* GraphQL */ `
  query GetHeroEquipment($id: ID!) {
    getHeroEquipment(id: $id) {
      id
      heroId
      equipmentId
      name
      displayName
      description
      type
      subType
      image
      backImage
      modifiers {
        attribute
        modification
        value
      }
      cost
      equipmentSlot
      isArtifact
      isExhausted
      isExhaustable
      isConsumable
      characterRestrictions
      pairedEquipmentRestrictions
      hero {
        id
        owner
        name
        displayName
        character
        attack
        defense
        body
        mind
        gold
        campaign
        completedQuest {
          nextToken
        }
        equipment {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const listHeroEquipments = /* GraphQL */ `
  query ListHeroEquipments(
    $filter: ModelHeroEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeroEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        heroId
        equipmentId
        name
        displayName
        description
        type
        subType
        image
        backImage
        modifiers {
          attribute
          modification
          value
        }
        cost
        equipmentSlot
        isArtifact
        isExhausted
        isExhaustable
        isConsumable
        characterRestrictions
        pairedEquipmentRestrictions
        hero {
          id
          owner
          name
          displayName
          character
          attack
          defense
          body
          mind
          gold
          campaign
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCompletedQuests = /* GraphQL */ `
  query ListCompletedQuests(
    $filter: ModelCompletedQuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompletedQuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        heroId
        number
        campaign
        hero {
          id
          owner
          name
          displayName
          character
          attack
          defense
          body
          mind
          gold
          campaign
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const heroEquipmentsByHeroId = /* GraphQL */ `
  query HeroEquipmentsByHeroId(
    $heroId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHeroEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    heroEquipmentsByHeroId(
      heroId: $heroId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        heroId
        equipmentId
        name
        displayName
        description
        type
        subType
        image
        backImage
        modifiers {
          attribute
          modification
          value
        }
        cost
        equipmentSlot
        isArtifact
        isExhausted
        isExhaustable
        isConsumable
        characterRestrictions
        pairedEquipmentRestrictions
        hero {
          id
          owner
          name
          displayName
          character
          attack
          defense
          body
          mind
          gold
          campaign
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const heroEquipmentsByEquipmentId = /* GraphQL */ `
  query HeroEquipmentsByEquipmentId(
    $equipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHeroEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    heroEquipmentsByEquipmentId(
      equipmentId: $equipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        heroId
        equipmentId
        name
        displayName
        description
        type
        subType
        image
        backImage
        modifiers {
          attribute
          modification
          value
        }
        cost
        equipmentSlot
        isArtifact
        isExhausted
        isExhaustable
        isConsumable
        characterRestrictions
        pairedEquipmentRestrictions
        hero {
          id
          owner
          name
          displayName
          character
          attack
          defense
          body
          mind
          gold
          campaign
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
