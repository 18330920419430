import { createSelector } from "reselect";
import { selectedHeroCampaign } from "@entities/heroes/model/heroesSelectors";
import { getCampaignItems } from "../viewCampaignUtilities";

import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import filter from "lodash/filter";

import { potions } from "@entities/equipment/model/potions";
import { items } from "@entities/equipment/model/items";
import { armor } from "@entities/equipment/model/armor";
import { weapons } from "@entities/equipment/model/weapons";

export const getArtifactItems = (items) =>
  filter(items, (item) => item?.isArtifact === true);

export const armorArtifactList = getArtifactItems(armor);
export const potionArtifactList = getArtifactItems(potions);
export const itemArtifactList = getArtifactItems(items);
export const weaponArtifactList = getArtifactItems(weapons);
export const itemsAndPotionsArtifactList = sortBy(
  concat(itemArtifactList, potionArtifactList),
  ["name"]
);

export const potionArtifactListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(potionArtifactList, heroCampaign)
);
export const itemArtifactListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemArtifactList, heroCampaign)
);
export const armorArtifactListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(armorArtifactList, heroCampaign)
);
export const weaponArtifactListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(weaponArtifactList, heroCampaign)
);

export const itemsAndPotionsArtifactListForHeroCampaign = createSelector(
  selectedHeroCampaign,
  (heroCampaign) => getCampaignItems(itemsAndPotionsArtifactList, heroCampaign)
);

export const artifactsListForHeroCampaign = createSelector(
  weaponArtifactListForHeroCampaign,
  armorArtifactListForHeroCampaign,
  itemArtifactListForHeroCampaign,
  potionArtifactListForHeroCampaign,
  (weapons, armor, items, potion) => {
    return sortBy([...weapons, ...armor, ...items, ...potion], ["name"]);
  }
);
