import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import {
  selectedHeroCampaign,
  selectedHeroId,
} from "@entities/heroes/model/heroesSelectors";
import { campaigns } from "@entities/campaign/model/constants/campaigns";
import useHeroAPI from "@entities/heroes/hooks/useHeroAPI";

export const CampaignSelectMenu = () => {
  const { updateHeroCampaign } = useHeroAPI();
  const heroId = useSelector(selectedHeroId);
  const campaignSelection = useSelector(selectedHeroCampaign);

  const handleChange = (event) => {
    const campaign = event.target.value;
    updateHeroCampaign({ campaign, heroId });
  };

  const menuItems = campaigns.map((item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.display}
      </MenuItem>
    );
  });

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={campaignSelection}
          label="Campaign"
          onChange={handleChange}
          autoWidth
        >
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CampaignSelectMenu;
